import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./styles.scss";

SimpleInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  // ...props
};

SimpleInput.defaultProps = {
  invalid: false,
};

export default function SimpleInput({ onChange, invalid, ...restProps }) {
  return (
    <input
      type="text"
      {...restProps}
      className={classnames(styles.root, { [styles.invalid]: invalid })}
      onChange={event => {
        onChange(event.currentTarget.value);
      }}
    />
  );
}
