import PropTypes from "prop-types";
import React from "react";

import styles from "./styles.scss";

ToggleButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  highlighted: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  // ...props
};

ToggleButton.defaultProps = {
  highlighted: false,
};

export default function ToggleButton({
  selected,
  highlighted,
  onClick,
  children,
  ...restProps
}) {
  return (
    <button
      type="button"
      {...restProps}
      className={
        selected
          ? styles.selected
          : highlighted
          ? styles.highlighted
          : styles.unselected
      }
      onClick={() => {
        onClick();
      }}
    >
      {children}
    </button>
  );
}
