import React from "react";
import pythonFormat from "string-format";

import { blueprintProps } from "../../components/Blueprint";
import { escapeHtml } from "../../utils";
import {
  ATTACHMENT_TYPES,
  ATTACHMENT_TYPES_API,
  BUTTONS_MODES,
  CHOICES_DISPLAYS,
  CHOICES_GROUPS,
  FIELD_TYPES,
  OPTIMISTIC_REPLY_ID,
} from "./constants";

export function makeOptimisticReplyTo(message, attachment, data) {
  const template = message.textReply;

  if (template == null) {
    return undefined;
  }

  const escapedData = Object.entries(data).reduce((result, [key, value]) => {
    const humanizedValue = humanizeValue(attachment, value);
    result[key] = escapeHtml(String(humanizedValue));
    return result;
  }, {});

  const text = pythonFormat(template, escapedData);

  if (text.trim() === "") {
    return undefined;
  }

  return {
    id: OPTIMISTIC_REPLY_ID,
    text,
    textReply: null,
    title: null,
    errors: [],
    isBot: false,
    attachments: [],
  };
}

function humanizeValue(attachment, value) {
  switch (attachment.type) {
    case ATTACHMENT_TYPES.BLUEPRINTS:
    case ATTACHMENT_TYPES.BUTTONS:
    case ATTACHMENT_TYPES.POSITIONS: {
      // `Array.isArray(value)` cannot be used here since some single values may
      // be arrays themselves!
      const valueArray =
        attachment.type === ATTACHMENT_TYPES.BUTTONS &&
        attachment.mode === BUTTONS_MODES.MULTIPLE
          ? value
          : [value];
      const choices = getAllChoices(attachment.choices)
        .filter(choice => valueArray.includes(choice.value))
        .map(choice => choice.name);
      const items = choices.length > 0 ? choices : valueArray;
      return items.join(", ");
    }

    case ATTACHMENT_TYPES.DAY:
    case ATTACHMENT_TYPES.FORM:
    case ATTACHMENT_TYPES.JSON:
    case ATTACHMENT_TYPES.SIZE:
    default:
      return value;
  }
}

export function transformMessage(message) {
  const attachments = message.attachments.map(attachment => ({
    ...transformAttachment(attachment),
    // string | null
    title: attachment.title,
    // The original attachment from the API for debugging.
    _originalAttachment: attachment,
  }));

  return {
    // string
    id: message.id,
    // string (HTML)
    text: message.text,
    // string | null
    textReply: message.data.text_reply,
    // string | null
    helpName: message.data.help_name,
    // string | null
    title: message.title,
    // number | null
    timeline: message.data.timeline,
    // Array<string>
    errors: [].concat(
      ...message.attachments.map(attachment =>
        extractErrorsFromAttachment(attachment),
      ),
    ),
    // boolean
    isBot: message.is_bot,
    // object
    data: message.data,
    // Array<Attachment> (see transformAttachment)
    attachments,
  };
}

function transformAttachment(attachment) {
  switch (attachment.type) {
    case ATTACHMENT_TYPES_API.CHOICES:
      switch (attachment.display) {
        case CHOICES_DISPLAYS.BLUEPRINT:
          return {
            type: ATTACHMENT_TYPES.BLUEPRINTS,
            // { standard: Array<Choice>, popular: Array<Choice>, other: Array<Choice> }
            choices: groupChoices(
              attachment.choices.map(choice => {
                const data = attachment.data
                  ? attachment.data[choice.value]
                  : null;
                return {
                  // string
                  name: choice.name,
                  // any
                  value: choice.value,
                  // string (HTML) | null
                  description: choice.description,
                  // string | null
                  chooseText: choice.choose_text,
                  // Blueprint | null
                  // TODO: Get parapet and pass it to `blueprintProps`
                  blueprint: data == null ? null : blueprintProps(data),
                  // string | null
                  image: choice.image,
                  // number | null
                  group: choice.group,
                  // boolean
                  selected: choice.selected,
                };
              }),
            ),
          };

        case CHOICES_DISPLAYS.BUTTONS:
          return {
            type: ATTACHMENT_TYPES.BUTTONS,
            // BUTTONS_MODES.* from ./constants.js
            mode: attachment.multiple
              ? BUTTONS_MODES.MULTIPLE
              : BUTTONS_MODES.SINGLE,
            // string | null
            name: null,
            // { standard: Array<Choice>, popular: Array<Choice>, other: Array<Choice> }
            choices: groupChoices(
              attachment.choices.map(choice => ({
                // string
                name: choice.name,
                // any
                value: choice.value,
                // number | null
                group: choice.group,
                // boolean
                selected: choice.selected,
              })),
            ),
          };

        default:
          console.warn(
            "Unknown choices display.",
            attachment.display,
            attachment,
          );
          return {
            type: ATTACHMENT_TYPES.JSON,
            data: {},
          };
      }

    case ATTACHMENT_TYPES_API.DATE:
      return {
        type: ATTACHMENT_TYPES.DAY,
        // string | null
        selectedDate: attachment.date,
        // string
        today: attachment.today,
        // Array<string>
        disabledDates: attachment.disabled_dates,
        // Array<number>
        disabledWeekdays: attachment.disabled_weekdays,
        // string | null
        date: attachment.date,
      };

    case ATTACHMENT_TYPES_API.FORM: {
      const loneField =
        attachment.fields.length === 1 && attachment.fields[0].length === 1
          ? attachment.fields[0][0]
          : undefined;

      if (
        loneField != null &&
        loneField.type === FIELD_TYPES.CHAR_WITH_CHOICES
      ) {
        return {
          type: ATTACHMENT_TYPES.BUTTONS,
          // BUTTONS_MODES.* from ./constants.js
          mode: BUTTONS_MODES.CUSTOM_TEXT,
          // string | null
          name: loneField.name,
          // { standard: Array<Choice>, popular: Array<Choice>, other: Array<Choice> }
          choices: groupChoices(
            loneField.choices.map(value => ({
              // string
              name: value,
              // any in general, string in this specific case
              value,
            })),
          ),
          // any in general, string in this specific case
          value: loneField.value,
        };
      }

      return {
        type: ATTACHMENT_TYPES.FORM,
        fields: attachment.fields.map(row =>
          row.map(field => ({
            ...transformField(field),
            type: field.type,
            // string
            name: field.name,
            // any
            value: field.value,
            // Array<string>
            errors: field.errors,
          })),
        ),
      };
    }

    case ATTACHMENT_TYPES_API.POSITIONS:
      return {
        type: ATTACHMENT_TYPES.POSITIONS,
        // Array<Choice>
        choices: attachment.choices.map(choice => ({
          // string
          name: choice.name,
          // POSITIONS.* from ./constants.js
          value: choice.value,
          // boolean
          selected: choice.selected,
        })),
      };

    case ATTACHMENT_TYPES_API.WINDOW_SIZE:
      return {
        type: ATTACHMENT_TYPES.SIZE,
        // number (defaults to 0)
        width: attachment.width,
        // number (defaults to 0)
        height: attachment.height,
        // number | null (available when the user gave a too large width)
        suggestedWidth:
          attachment.suggested_width === false
            ? null
            : attachment.suggested_width,
        // number | null (available when the user gave a too large height)
        suggestedHeight:
          attachment.suggested_height === false
            ? null
            : attachment.suggested_height,
      };

    default:
      // This is `console.error`:ed in `extractErrorsFromAttachment`.
      return {
        type: ATTACHMENT_TYPES.JSON,
        data: {},
      };
  }
}

function transformField(field) {
  switch (field.type) {
    case FIELD_TYPES.INTEGER:
      return {
        // number | null
        maxValue: field.max_value,
        // number | null
        minValue: field.min_value,
      };

    case FIELD_TYPES.CHAR_WITH_CHOICES:
      return {
        // Array<string>
        choices: field.choices,
      };

    case FIELD_TYPES.CHAR:
    case FIELD_TYPES.DECIMAL:
    case FIELD_TYPES.EMAIL:
    case FIELD_TYPES.PHONE:
    case FIELD_TYPES.POSTAL_CODE:
    default:
      return {};
  }
}

function extractErrorsFromAttachment(attachment) {
  switch (attachment.type) {
    case ATTACHMENT_TYPES_API.CHOICES:
      return attachment.error
        ? attachment.multiple
          ? ["Välj ett eller flera av alternativen"]
          : ["Välj ett av alternativen"]
        : [];

    case ATTACHMENT_TYPES_API.DATE:
      return [];

    case ATTACHMENT_TYPES_API.FORM:
      return attachment.errors.concat(
        ...[].concat(...attachment.fields).map(field => field.errors),
      );

    case ATTACHMENT_TYPES_API.POSITIONS:
      return [];

    case ATTACHMENT_TYPES_API.WINDOW_SIZE:
      return attachment.error == null ? [] : [attachment.error];

    default:
      console.error("Unknown attachment type", attachment.type, attachment);
      return [];
  }
}

export function groupChoices(choices) {
  const choicesGroupsValues = Object.values(CHOICES_GROUPS);
  return {
    standard: choices.filter(
      choice => choice.group === CHOICES_GROUPS.STANDARD,
    ),
    popular: choices.filter(choice => choice.group === CHOICES_GROUPS.POPULAR),
    other: choices.filter(
      choice => !choicesGroupsValues.includes(choice.group),
    ),
  };
}

export function hasSeveralChoicesGroups(groupedChoices) {
  return (
    Object.values(groupedChoices).filter(choices2 => choices2.length > 0)
      .length > 1
  );
}

export function titleChoices(groupedChoices) {
  return [
    {
      id: "standard",
      title: (
        <>
          Standard <small>(vanligast)</small>
        </>
      ),
      choices: groupedChoices.standard,
    },
    {
      id: "popular",
      title:
        groupedChoices.standard.length === 0 ? "Populära" : "Andra populära",
      choices: groupedChoices.popular,
    },
    {
      id: "other",
      title: "Övriga",
      choices: groupedChoices.other,
    },
  ].filter(item => item.choices.length > 0);
}

export function getAllChoices(choices) {
  if (Array.isArray(choices)) {
    return choices;
  }

  if (choices != null && typeof choices === "object") {
    return [].concat(...Object.values(choices).filter(Array.isArray));
  }

  return undefined;
}

// Extract reply data from an attachment that the user already has answered and
// submitted. Used to re-send a previous message when undoing. If no such data
// can be found, `undefined` is returned.
export function remakeAttachmentReplyData(attachment) {
  switch (attachment.type) {
    case ATTACHMENT_TYPES.BLUEPRINTS:
    case ATTACHMENT_TYPES.POSITIONS: {
      const choice = getAllChoices(attachment.choices).find(
        choice2 => choice2.selected,
      );
      return choice == null ? undefined : { value: choice.value };
    }

    case ATTACHMENT_TYPES.BUTTONS: {
      if (attachment.mode === BUTTONS_MODES.CUSTOM_TEXT) {
        const { value } = attachment;
        return value == null ? undefined : { [attachment.name]: value };
      }
      const values = getAllChoices(attachment.choices)
        .filter(choice => choice.selected)
        .map(choice => choice.value);
      return values.length === 0
        ? undefined
        : {
            value:
              attachment.mode === BUTTONS_MODES.MULTIPLE ? values : values[0],
          };
    }

    case ATTACHMENT_TYPES.DAY: {
      const { date } = attachment;
      return date == null ? undefined : { date };
    }

    case ATTACHMENT_TYPES.FORM: {
      const fields = [].concat(...attachment.fields);
      return fields.some(field => field.value == null)
        ? undefined
        : fields.reduce((result, field) => {
            result[field.name] = field.value;
            return result;
          }, {});
    }

    case ATTACHMENT_TYPES.JSON:
      return undefined;

    case ATTACHMENT_TYPES.SIZE: {
      const { width, height } = attachment;
      return { width, height };
    }

    default:
      console.error("Unknown attachment type", attachment.type, attachment);
      return undefined;
  }
}
