import React from "react";

import styles from "./styles.scss";

export default function SendButton({ ...restProps }) {
  return (
    <button type="submit" {...restProps} className={styles.root}>
      Skicka
    </button>
  );
}
