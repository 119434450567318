import React from "react";

import { range } from "../../utils";
import styles from "./styles.scss";

export default function DotsSpinner() {
  return (
    <span className={styles.root} aria-label="Laddar…">
      {range(3, n => (
        <span
          key={n}
          className={styles.dot}
          style={{ animationDelay: `${n * 200}ms` }}
        />
      ))}
    </span>
  );
}
