import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { onWindowResize } from "../../utils/dom";

const UPDATE_TIMEOUT = 100; // ms
const FONTACTIVE_TIMEOUT = 50; // ms

/**
 * If the rendering of a component depends on taking measurements from the DOM,
 * those measurements need to be redone when things change. That's what this
 * component is for. `onCalculation` is run when:
 *
 * - The component mounts.
 * - The component updates.
 * - The window resizes.
 * - A font is loaded.
 *
 * `onCalculation` is debounced or throttled (depending on the situation).
 */
const instances = new Set();

export default class WithDomCalculation extends React.Component {
  static propTypes = {
    onCalculation: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  };

  throttledOnCalculation = _.throttle(() => {
    const { onCalculation } = this.props;
    onCalculation();
  }, UPDATE_TIMEOUT);

  componentDidMount() {
    // Run `onCalculation` in the next tick so that the parent component has had
    // a chance to mount and get all `ref`s.
    setTimeout(() => {
      const { onCalculation } = this.props;
      instances.add(this);
      onCalculation();
    }, 0);
  }

  componentWillUnmount() {
    instances.delete(this);
  }

  componentDidUpdate() {
    this.throttledOnCalculation();
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

function callOnCalculation() {
  for (const instance of instances) {
    const { onCalculation } = instance.props;
    onCalculation();
  }
}

// Add listeners once for all instances and batch updates.
if (typeof document !== "undefined") {
  document.addEventListener(
    "fontactive", // From font.js.
    _.throttle(callOnCalculation, FONTACTIVE_TIMEOUT),
    true,
  );
  onWindowResize(callOnCalculation);
}
