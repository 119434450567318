import _ from "lodash";

/**
 * Focuses an element for accessibility reasons.
 *
 * - Adds `tabindex="-1"` temporarily to make sure that the element can be
 *   focused, and does not get an outline.
 * - Prevents the browser from changing the scroll position.
 */
export function accessibilityFocus(element) {
  const { pageXOffset, pageYOffset } = window;
  const originalTabindex = element.tabIndex;
  element.tabIndex = -1;
  element.focus();
  element.tabindex = originalTabindex;
  window.scrollTo(pageXOffset, pageYOffset);
}

/**
 * Runs `fn` `timeout` milliseconds after the window size stops changing.
 */
export function onWindowResize(fn, timeout = 250) {
  if (typeof window === "undefined") {
    return () => {
      // Do nothing.
    };
  }

  const wrapper = _.debounce(fn, timeout);
  const options = { capture: true, passive: true };

  const events = ["resize", "orientaionchange"];

  events.forEach(eventName => {
    window.addEventListener(eventName, wrapper, options);
  });

  return () => {
    events.forEach(eventName => {
      window.removeEventListener(eventName, wrapper, options);
    });
  };
}
