import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./styles.scss";

Conversation.propTypes = {
  landing: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        right: PropTypes.bool.isRequired,
        children: PropTypes.node.isRequired,
        image: PropTypes.oneOfType([
          PropTypes.string.isRequired,
          PropTypes.node.isRequired,
        ]),
      }).isRequired,
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        heading: PropTypes.node.isRequired,
      }).isRequired,
    ]).isRequired,
  ).isRequired,
};

Conversation.defaultProps = {
  landing: false,
};

export default function Conversation({ landing, items }) {
  return (
    <div className={classnames(styles.root, { [styles.landing]: landing })}>
      {items.map(item =>
        item.heading != null ? (
          <h2 key={item.id} className={styles.heading}>
            {item.heading}
          </h2>
        ) : (
          <div
            key={item.id}
            id={itemIdToDomId(item.id)}
            className={classnames(styles.message, {
              [styles.left]: !item.right,
              [styles.right]: item.right,
            })}
          >
            {typeof item.image === "string" ? (
              <img src={item.image} alt="" className={styles.image} />
            ) : item.image == null ? null : (
              item.image
            )}

            <div>{item.children}</div>
          </div>
        ),
      )}
    </div>
  );
}

export function itemIdToDomId(itemId) {
  return `conversation-message-${itemId}`;
}
