import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Tweezer from "tweezer.js";

import ChevronRightIcon from "../../icons/chevron-right.svg";
import { getScrollbarWidth } from "../../utils/scroll";
import Icon from "../Icon";
import WithDomCalculation from "../WithDomCalculation";
import styles from "./styles.scss";

// The part of the scroller width to scroll on every arrow click.
const SCROLL_FRACTION = 3 / 4;
const SCROLL_VELOCITY = 1.5; // px/ms
const MIN_SCROLL_START_END_OFFSET = 50; // px
const MIN_SCROLL_DURATION = 200; // ms

export default class HorizontalScroller extends React.Component {
  scrollElement = undefined;
  tweezer = undefined;

  static propTypes = {
    // Passing in styles/classNames is required.
    styles: PropTypes.shape({
      // Give the root area a width.
      root: PropTypes.string.isRequired,
      // Allows to style things based on whether the arrows are present or not.
      hasArrows: PropTypes.string.isRequired,
      // You only need to style the left arrow. Give it a gradient. The right
      // arrow is simply the left arrow flipped.
      arrow: PropTypes.string.isRequired,
    }).isRequired,
    children: PropTypes.node.isRequired,
  };

  state = {
    scrollLeft: 0,
    scrollLeftMax: 0,
  };

  render() {
    const { styles: passedStyles, children } = this.props;
    const { scrollLeftMax } = this.state;

    return (
      <WithDomCalculation onCalculation={this.calculateScroll.bind(this)}>
        <div
          className={classnames(styles.root, passedStyles.root, {
            [passedStyles.hasArrows]: scrollLeftMax > 0,
          })}
        >
          {this.renderArrow({ isLeft: true })}

          <div className={styles.scrollWrapper}>
            <div
              className={styles.scroll}
              // Hide scrollbar.
              style={{ marginBottom: -getScrollbarWidth() }}
              onScroll={this.calculateScroll.bind(this)}
              // The children might change state on click and keydown which
              // might add or remove elements, so update the scroller when such
              // events occur inside.
              onClick={() => {
                setTimeout(this.calculateScroll.bind(this), 0);
              }}
              onKeyDown={() => {
                setTimeout(this.calculateScroll.bind(this), 0);
              }}
              ref={element => {
                this.scrollElement = element;
              }}
            >
              {children}
            </div>
          </div>

          {this.renderArrow({ isLeft: false })}
        </div>
      </WithDomCalculation>
    );
  }

  renderArrow({ isLeft }) {
    const { styles: passedStyles } = this.props;
    const { scrollLeftMax } = this.state;
    const enabled = this.isArrowEnabled({ isLeft });

    if (scrollLeftMax === 0) {
      return null;
    }

    return (
      <button
        type="button"
        disabled={!enabled}
        className={classnames(styles.arrow, passedStyles.arrow, {
          [styles.arrowRight]: !isLeft,
        })}
        onClick={() => {
          this.onArrow({ isLeft });
        }}
        tabIndex={enabled ? undefined : -1}
      >
        <Icon icon={ChevronRightIcon} className={styles.arrowIcon} />
      </button>
    );
  }

  isArrowEnabled({ isLeft }) {
    const { scrollLeft, scrollLeftMax } = this.state;

    return isLeft ? scrollLeft > 0 : scrollLeft < scrollLeftMax;
  }

  onArrow({ isLeft }) {
    const { scrollLeft, scrollLeftMax } = this.state;
    const sign = isLeft ? -1 : 1;

    if (!this.scrollElement) {
      return;
    }

    const newScrollLeft =
      scrollLeft + this.scrollElement.clientWidth * SCROLL_FRACTION * sign;

    const minScrollStartEndOffset =
      scrollLeftMax <= MIN_SCROLL_START_END_OFFSET * 2
        ? 0
        : MIN_SCROLL_START_END_OFFSET;

    // If clicking an arrow button would end up _almost_ at the left or right
    // edge then scroll to the very edge instead. This is both nicer UX wise as
    // well as needed for Safari which calculates things a little differently
    // and easily ends up ~1px or so from the edge when first going left and
    // then right again.
    const clampedScrollLeft =
      newScrollLeft < minScrollStartEndOffset
        ? 0
        : newScrollLeft > scrollLeftMax - minScrollStartEndOffset
        ? scrollLeftMax
        : newScrollLeft;

    const start = scrollLeft;
    const end = clampedScrollLeft;
    const distance = Math.abs(start - end);
    const duration = Math.max(MIN_SCROLL_DURATION, distance / SCROLL_VELOCITY);

    if (this.tweezer != null) {
      this.tweezer.stop();
    }

    this.tweezer = new Tweezer({ start, end, duration })
      .on("tick", value => {
        this.scrollElement.scrollLeft = value;
      })
      .on("done", () => {
        this.tweezer = undefined;
      })
      .begin();
  }

  calculateScroll() {
    if (this.scrollElement == null) {
      return;
    }

    const newState = {
      scrollLeft: this.scrollElement.scrollLeft,
      scrollLeftMax:
        this.scrollElement.scrollWidth - this.scrollElement.clientWidth,
    };

    if (
      newState.scrollLeft !== this.state.scrollLeft ||
      newState.scrollLeftMax !== this.state.scrollLeftMax
    ) {
      this.setState(newState);
    }
  }
}
