import PropTypes from "prop-types";
import React from "react";
import ReactDayPicker from "react-day-picker";

import localeProps from "./locale";
import styles from "./styles.scss";

export default class DayPicker extends React.Component {
  static propTypes = {
    enabled: PropTypes.bool,
    selectedDate: PropTypes.instanceOf(Date),
    today: PropTypes.instanceOf(Date),
    disabledFromTodayOffset: PropTypes.number,
    disabledDates: PropTypes.arrayOf(PropTypes.instanceOf(Date).isRequired),
    disabledWeekdays: PropTypes.arrayOf(PropTypes.number.isRequired),
    onDayClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    enabled: true,
    selectedDate: undefined,
    today: new Date(),
    disabledFromTodayOffset: 0,
    disabledDates: [],
    disabledWeekdays: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedDate: props.selectedDate,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      (nextProps.selectedDate == null) !== (prevState.selectedDate == null) ||
      (nextProps.selectedDate != null &&
        prevState.selectedDate != null &&
        nextProps.selectedDate.getTime() !== prevState.selectedDate.getTime())
    ) {
      return { selectedDate: nextProps.selectedDate };
    }

    return null;
  }

  render() {
    const {
      enabled,
      today,
      disabledFromTodayOffset,
      disabledDates,
      disabledWeekdays,
      onDayClick,
    } = this.props;
    const { selectedDate } = this.state;

    const firstEnabledDay = new Date(today);
    firstEnabledDay.setDate(
      firstEnabledDay.getDate() + disabledFromTodayOffset + 1,
    );

    return (
      <ReactDayPicker
        {...localeProps}
        classNames={styles}
        initialMonth={today}
        month={enabled ? undefined : selectedDate || today}
        fromMonth={today}
        fixedWeeks
        modifiers={{
          [styles.today]: today,
          [styles.selected]: selectedDate,
          [styles.disabled]: [
            {
              before: firstEnabledDay,
            },
            {
              daysOfWeek: disabledWeekdays.map(
                dayNum => (dayNum + localeProps.firstDayOfWeek) % 7,
              ),
            },
            ...disabledDates,
          ],
        }}
        onDayClick={
          enabled
            ? (date, { [styles.disabled]: disabled }) => {
                if (!disabled) {
                  onDayClick(date);
                  this.setState({ selectedDate: date });
                }
              }
            : undefined
        }
      />
    );
  }
}
