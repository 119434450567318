import Tweezer from "tweezer.js";

const SCROLL_DURATION = 500; // ms

let scrollbarWidth = undefined;

export function scrollToElement(element, offset = 0) {
  new Tweezer({
    start: window.pageYOffset,
    end: element.getBoundingClientRect().top + window.scrollY + offset,
    duration: SCROLL_DURATION,
  })
    .on("tick", v => window.scrollTo(0, v))
    .begin();
}

export function getScrollbarWidth() {
  if (scrollbarWidth != null) {
    return scrollbarWidth;
  }

  const box = document.createElement("div");
  Object.assign(box.style, {
    position: "fixed",
    zIndex: -1,
    visibility: "hidden",
    width: "100px",
    height: "100px",
    overflow: "scroll",
  });

  const child = document.createElement("div");
  Object.assign(child.style, {
    width: "100%",
    height: "200%",
  });

  box.appendChild(child);
  document.body.appendChild(box);

  scrollbarWidth = box.offsetWidth - box.clientWidth;

  document.body.removeChild(box);

  return scrollbarWidth;
}

const tweezers = new WeakMap();

/**
 * Smoothly scroll an element to the bottom. Automatically aborts the
 * scrolling if another one is started or the user starts scrolling.
 */
export function scrollElementToBottom(
  element,
  { duration = SCROLL_DURATION } = {},
) {
  const y = element.scrollHeight - element.clientHeight;

  const oldTweezer = tweezers.get(element);

  if (oldTweezer != null) {
    oldTweezer.stop();
    tweezers.delete(oldTweezer);
  }

  if (duration <= 0) {
    element.scrollTop = y;
    return;
  }

  const tweezer = new Tweezer({
    start: element.scrollTop,
    end: y,
    duration,
  })
    .on("tick", value => {
      element.scrollTop = value;
    })
    .on("done", () => {
      tweezers.delete(element);
    })
    .begin();

  tweezers.set(element, tweezer);
}
