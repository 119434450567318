/*

IMPORTANT!

This file is also used in a custom server endpoint! WAAH!
The admin in doowin-api fetches `/blueprint?params` to show blueprints.

THIS MEANS THERE ARE SOME RULES:

- NO IMPORTS besides prop-types and react.
- Only inline styling.

In other words, this file needs to be as standalone as possible. No dependencies
on other JS, CSS or config.

*/

// DON'T ADD MORE IMPORTS! SEE ABOVE!
import PropTypes from "prop-types";
import React from "react";

// Sizes in **mm.**
const FRAME_WIDTH = 60;
const LUFT_DIVIDER_WIDTH = FRAME_WIDTH;
const SASH_WIDTH = 30;
const POST_WIDTH = SASH_WIDTH;
const MULLION_WIDE_WIDTH = POST_WIDTH;
const MULLION_THIN_WIDTH = 20;
const UPPER_MULLION_WIDTH = MULLION_THIN_WIDTH;

const areaStyle = {
  fill: "none",
  vectorEffect: "non-scaling-stroke",
};

const stroke = "#ccc";
const glassColor = "#c1e9fa";
const fillColor = "#fff";

// A “layer” is a rectangle that can contain a grid of other rectangles.
// They work a little bit like `<div style="display: grid; padding: XXpx;">`.
// All dimensions in a layer are in **px.**
const layerPropType = PropTypes.shape({
  // Padding inside the rectangle, around its children. Used to create the frame
  // and the sash.
  padding: PropTypes.number.isRequired,

  // Distance between children. This creates the illusion of luft dividers,
  // posts and mullions.
  gapH: PropTypes.number.isRequired,
  gapV: PropTypes.number.isRequired,

  // The number of gaps in each direction. 0 makes a single area.
  numGapsH: PropTypes.number.isRequired,
  numGapsV: PropTypes.number.isRequired,

  // Width and height in pixels of the rectangle.
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,

  // The top row can have a different height sometimes. I’m not sure if this
  // nests correctly. See `squareUpperPostAreas` for more information.
  topHeight: PropTypes.number,

  // Each row of lufts can have upper mullions, so it needs to reset what a
  // “top” row means.
  topRoot: PropTypes.bool.isRequired,

  // Whether this layer should only be shown in a “top” row. Used for the upper
  // mullions.
  topOnly: PropTypes.bool.isRequired,

  // React style object for styling the layer.
  style: PropTypes.object.isRequired,
});

Blueprint.propTypes = {
  // Width of the SVG in **px.**
  svgWidth: PropTypes.number.isRequired,

  // Width and height of the entire window or door in **mm.**
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,

  // Height of the section below the window in a window door in **mm.**
  midRailHeight: PropTypes.number,

  // Convention: `foosH` and `foosV` means “number of horizontal/vertical foos”.
  luftsH: PropTypes.number,
  luftsV: PropTypes.number,
  postsH: PropTypes.number,
  postsV: PropTypes.number,
  mullionsH: PropTypes.number,
  mullionsV: PropTypes.number,
  upperMullionsH: PropTypes.number,
  upperMullionsV: PropTypes.number,

  // Mullions can be either thin or wide. Upper mullions are always thin.
  mullionsHAreWide: PropTypes.bool,
  mullionsVAreWide: PropTypes.bool,

  coloredGlass: PropTypes.bool,
};

Blueprint.defaultProps = {
  midRailHeight: 0,
  luftsH: 1,
  luftsV: 1,
  postsH: 0,
  postsV: 0,
  mullionsH: 0,
  mullionsV: 0,
  upperMullionsH: 0,
  upperMullionsV: 0,
  mullionsHAreWide: false,
  mullionsVAreWide: false,
  coloredGlass: true,
};

export default function Blueprint({
  svgWidth,
  width: widthMM,
  height: heightMM,
  midRailHeight: midRailHeightMM,
  luftsH,
  luftsV,
  postsH,
  postsV,
  mullionsH,
  mullionsV,
  upperMullionsH,
  upperMullionsV,
  mullionsHAreWide,
  mullionsVAreWide,
  coloredGlass,
}) {
  const scale = svgWidth / widthMM; // px/mm
  const midRailHeight =
    Math.min(midRailHeightMM, heightMM - FRAME_WIDTH) * scale;
  const windowWidth = svgWidth;
  const windowHeight = Math.max(
    (windowWidth * heightMM) / widthMM - midRailHeight,
    0,
  );
  const svgHeight = windowHeight + midRailHeight;

  // Dummy layer to make stuff easier. It’s styled to not be visible.
  const container = {
    padding: 0,
    gapH: 0,
    gapV: 0,
    numGapsH: 0,
    numGapsV: 0,
    width: windowWidth,
    height: windowHeight,
    topHeight: undefined,
    topRoot: false,
    topOnly: false,
    style: areaStyle,
  };

  // Note: the `squareUpperPostAreas` function depends on the order of these
  // layers.
  const layers = [
    // Frames.
    {
      padding: FRAME_WIDTH * scale,
      gapH: LUFT_DIVIDER_WIDTH * scale,
      gapV: LUFT_DIVIDER_WIDTH * scale,
      numGapsH: luftsV - 1,
      numGapsV: luftsH - 1,
      width: 0,
      height: 0,
      topHeight: undefined,
      topRoot: false,
      topOnly: false,
      style: areaStyle,
    },
    // Lufts (with sash).
    {
      padding: SASH_WIDTH * scale,
      gapH: POST_WIDTH * scale,
      gapV: POST_WIDTH * scale,
      numGapsH: postsH,
      numGapsV: postsV,
      width: 0,
      height: 0,
      topHeight: undefined,
      topRoot: true,
      topOnly: false,
      style: { ...areaStyle, stroke },
    },
    // Post areas.
    {
      padding: 0 * scale,
      gapH:
        (mullionsHAreWide ? MULLION_WIDE_WIDTH : MULLION_THIN_WIDTH) * scale,
      gapV:
        (mullionsVAreWide ? MULLION_WIDE_WIDTH : MULLION_THIN_WIDTH) * scale,
      numGapsH: mullionsH,
      numGapsV: mullionsV,
      width: 0,
      height: 0,
      topHeight: undefined,
      topRoot: false,
      topOnly: false,
      style: { ...areaStyle, stroke },
    },
    // Mullion areas.
    {
      padding: 0 * scale,
      gapH: UPPER_MULLION_WIDTH * scale,
      gapV: UPPER_MULLION_WIDTH * scale,
      numGapsH: upperMullionsH,
      numGapsV: upperMullionsV,
      width: 0,
      height: 0,
      topHeight: undefined,
      topRoot: false,
      topOnly: false,
      style: { ...areaStyle, stroke },
    },
    // Upper mullion areas.
    {
      padding: 0 * scale,
      gapH: 0 * scale,
      gapV: 0 * scale,
      numGapsH: 0,
      numGapsV: 0,
      width: 0,
      height: 0,
      topHeight: undefined,
      topRoot: false,
      topOnly: true,
      style: { ...areaStyle, stroke },
    },
  ];

  const layersWithDimensions = mapWithContext(
    layers,
    container,
    (layer, parent) => {
      const newLayer = {
        ...layer,
        width: calculateLayerWidth(parent),
        height: calculateLayerHeight(parent),
      };
      return [newLayer, newLayer];
    },
  );

  const layersWithTopHeights = squareUpperPostAreas(layersWithDimensions);

  return (
    svgWidth > 0 &&
    svgHeight > 0 && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${svgWidth} ${svgHeight}`}
        width={svgWidth}
        height={svgHeight}
      >
        <rect
          x={0.5}
          y={0.5}
          width={svgWidth - 1}
          height={svgHeight - 1}
          style={{ ...areaStyle, stroke, fill: fillColor }}
        />

        <Layer
          layer={container}
          childLayers={layersWithTopHeights}
          x={0}
          y={0}
          isTop={true}
          coloredGlass={coloredGlass}
        />

        {midRailHeight > 0 && (
          <rect
            x={FRAME_WIDTH * scale}
            y={windowHeight}
            width={svgWidth - FRAME_WIDTH * scale * 2}
            height={midRailHeight - FRAME_WIDTH * scale}
            style={{ ...areaStyle, stroke }}
          />
        )}
      </svg>
    )
  );
}

Layer.propTypes = {
  layer: layerPropType.isRequired,
  childLayers: PropTypes.arrayOf(layerPropType.isRequired).isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  isTop: PropTypes.bool.isRequired,
  coloredGlass: PropTypes.bool.isRequired,
};

// There’s only one fitting word for describing the rendering approach taken
// here, and it is Swedish: “fiffig”. Rectangles in careful order (for z-index)
// give the illusion of drawing luft dividers, posts and mullions.
function Layer({ layer, childLayers, x, y, isTop, coloredGlass }) {
  const { width } = layer;
  const height =
    isTop && layer.topHeight != null ? layer.topHeight : layer.height;
  const childLayer = childLayers[0];

  return (
    width > 0 &&
    height > 0 && (
      <>
        {childLayer == null || (childLayer.topOnly && !isTop) ? (
          <rect
            x={x}
            y={y}
            width={width}
            height={height}
            style={{ fill: coloredGlass ? glassColor : "none" }}
          />
        ) : (
          range(layer.numGapsV + 1, ix =>
            range(layer.numGapsH + 1, iy => {
              const innerY =
                childLayer.topHeight == null
                  ? (childLayer.height + layer.gapH) * iy
                  : iy === 0
                  ? 0
                  : childLayer.topHeight +
                    childLayer.height * (iy - 1) +
                    layer.gapH * iy;
              return (
                <Layer
                  key={`${ix}-${iy}`}
                  layer={childLayer}
                  childLayers={childLayers.slice(1)}
                  x={x + layer.padding + (childLayer.width + layer.gapV) * ix}
                  y={y + layer.padding + innerY}
                  isTop={(isTop || layer.topRoot) && iy === 0}
                  coloredGlass={coloredGlass}
                />
              );
            }),
          )
        )}

        <rect x={x} y={y} width={width} height={height} style={layer.style} />
      </>
    )
  );
}

// Like `Array.prototype.map` except that you carry along a context of choice
// between each iteration.
function mapWithContext(array, startContext, fn) {
  const result = [];
  let context = startContext;

  array.forEach(item => {
    const [mappedItem, newContext] = fn(item, context);
    result.push(mappedItem);
    context = newContext;
  });

  return result;
}

function calculateLayerWidth(parent) {
  return Math.max(
    0,
    (parent.width - parent.padding * 2 - parent.gapV * parent.numGapsV) /
      (parent.numGapsV + 1),
  );
}

function calculateLayerHeight(parent, { topHeight = undefined } = {}) {
  const numAutoSized =
    topHeight == null ? parent.numGapsH + 1 : Math.max(1, parent.numGapsH);
  const fixedSize = topHeight == null ? 0 : topHeight;

  return Math.max(
    0,
    (parent.height -
      parent.padding * 2 -
      fixedSize -
      parent.gapH * parent.numGapsH) /
      numAutoSized,
  );
}

// This is a pretty ugly and complicated function that turns post top post areas
// with upper mullion areas into squares if a very specific set of rules are
// true. There might be some generalized way of doing this but I couldn't think
// of any.
function squareUpperPostAreas(layersWithDimensions) {
  const [
    frameLayer,
    luftLayer,
    postLayer,
    mullionLayer,
    upperMullionLayer,
  ] = layersWithDimensions;

  const shouldSquareUpperMulltions =
    // Exactly one horizontal post and at least one vertical post.
    luftLayer.numGapsH === 1 &&
    luftLayer.numGapsV >= 1 &&
    // No regular mullions.
    postLayer.numGapsH === 0 &&
    postLayer.numGapsV === 0 &&
    // Has upper mullions.
    (mullionLayer.numGapsH > 0 || mullionLayer.numGapsV > 0);

  if (!shouldSquareUpperMulltions) {
    return layersWithDimensions;
  }

  const postLayerTopHeight = postLayer.width; // Turn into square.

  // There are no regular mullions, so we can just use post layer height here.
  const mullionLayerTopHeight = postLayerTopHeight - postLayer.padding * 2;

  const upperMullionHeight =
    (mullionLayerTopHeight -
      mullionLayer.padding * 2 -
      mullionLayer.gapH * mullionLayer.numGapsH) /
    (mullionLayer.numGapsH + 1);

  const newPostLayer = {
    ...postLayer,
    // Let the non-top post areas share the remaining height after removing the
    // topHeight.
    height: calculateLayerHeight(luftLayer, { topHeight: postLayerTopHeight }),
    topHeight: postLayerTopHeight,
  };

  const newMullionLayer = {
    ...mullionLayer,
    // Let the non-top mullion areas share the height of the non-top post areas.
    // Passing `topHeight: 0` here is a bit of a hack but it works.
    height: calculateLayerHeight(newPostLayer, { topHeight: 0 }),
    topHeight: mullionLayerTopHeight,
  };

  const newUpperMullionLayer = {
    ...upperMullionLayer,
    height: upperMullionHeight,
  };

  // Make sure square upper mullion areas actually fit vertically. Also disallow
  // moving the horizontal post down.
  if (newPostLayer.topHeight > luftLayer.height / 2) {
    return layersWithDimensions;
  }

  return [
    frameLayer,
    luftLayer,
    newPostLayer,
    newMullionLayer,
    newUpperMullionLayer,
  ];
}

/**
 * Returns `[fn(0), fn(1), fn(2), ..., fn(n - 1)]`.
 */
function range(n, fn) {
  return Array.from({ length: n }, (_, i) => fn(i));
}

export function blueprintProps(data, parapet = undefined) {
  const glassHeight =
    parapet == null
      ? undefined
      : parapet.glass_height == null
      ? 1200 // Has parapet but the height is not known yet.
      : parapet.glass_height;

  return {
    // number
    width: data.width,
    // number
    height: data.height,
    // number
    luftsH: data.horizontal_lufts,
    // number
    luftsV: data.vertical_lufts,
    // number
    postsH: data.horizontal_posts || 0,
    // number
    postsV: data.vertical_posts || 0,
    // number
    mullionsH:
      data.horizontal_wide_mullions || data.horizontal_thin_mullions || 0,
    // number
    mullionsV: data.vertical_wide_mullions || data.vertical_thin_mullions || 0,
    // number
    upperMullionsH: data.horizontal_upper_mullions || 0,
    // number
    upperMullionsV: data.vertical_upper_mullions || 0,
    // boolean
    mullionsHAreWide: data.horizontal_wide_mullions > 0,
    // boolean
    mullionsVAreWide: data.vertical_wide_mullions > 0,
    // number
    midRailHeight:
      glassHeight == null ? 0 : Math.max(0, data.height - glassHeight),
  };
}
