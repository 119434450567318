const MONTHS = [
  "Januari",
  "Februari",
  "Mars",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "Augusti",
  "September",
  "Oktober",
  "November",
  "December",
];

const WEEKDAYS_LONG = [
  "Söndag",
  "Måndag",
  "Tisdag",
  "Onsdag",
  "Torsdag",
  "Fredag",
  "Lördag",
];

const WEEKDAYS_SHORT = ["S", "M", "T", "O", "T", "F", "L"];

export default {
  locale: "se",
  months: MONTHS,
  weekdaysLong: WEEKDAYS_LONG,
  weekdaysShort: WEEKDAYS_SHORT,
  firstDayOfWeek: 1,
  labels: {
    nextMonth: "Nästa månad",
    previousMonth: "Föregående månad",
  },
};
