import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { range } from "../../utils";
import styles from "./styles.scss";

const TIMELINE_TEXT = {
  1: "Berätta om din hustyp",
  2: "Välj fönster/dörr + tillval och tillbehör",
  3: "Din offert är klar!",
};

Timeline.propTypes = {
  timeline: PropTypes.number.isRequired,
};

export default function Timeline({ timeline }) {
  return (
    <ol className={classnames(styles.root)}>
      {range(3, n => {
        const step = n + 1;
        const text = TIMELINE_TEXT[step];
        return (
          <li
            key={step}
            className={classnames(styles.item, {
              [styles.highlighted]: step <= timeline,
              [styles.current]: step === timeline,
            })}
            aria-current={step === timeline ? "step" : null}
          >
            <div className={styles.circle}>{step}</div>
            {text != null && <p className={styles.text}>{text}</p>}
          </li>
        );
      })}
    </ol>
  );
}
