import PropTypes from "prop-types";
import React from "react";

import CrossIcon from "../../icons/cross.svg";
import Icon from "../Icon";
import NumberInput from "../NumberInput";
import SendButton from "../SendButton";
import simpleInputStyles from "../SimpleInput/styles.scss";
import styles from "./styles.scss";

const fieldPropType = PropTypes.shape({
  defaultValue: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
});

export default class SizeAttachment extends React.Component {
  static propTypes = {
    width: fieldPropType.isRequired,
    height: fieldPropType.isRequired,
    enabled: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    enabled: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      width: props.width.defaultValue,
      height: props.height.defaultValue,
    };
  }

  render() {
    const {
      width: widthProp,
      height: heightProp,
      enabled,
      onSubmit,
    } = this.props;
    const { width, height } = this.state;

    return (
      <form
        className={styles.root}
        onSubmit={event => {
          event.preventDefault();
          if (enabled) {
            onSubmit({ width, height });
          }
        }}
      >
        <Field
          value={width}
          label="Bredd"
          enabled={enabled}
          onChange={newWidth => {
            this.setState({ width: newWidth || 0 });
          }}
          min={widthProp.min == null ? undefined : widthProp.min}
          max={widthProp.max == null ? undefined : widthProp.max}
        />

        <Icon icon={CrossIcon} className={styles.cross} />

        <Field
          value={height}
          label="Höjd"
          enabled={enabled}
          onChange={newHeight => {
            this.setState({ height: newHeight || 0 });
          }}
          min={heightProp.min == null ? undefined : heightProp.min}
          max={heightProp.max == null ? undefined : heightProp.max}
        />

        <SendButton disabled={!enabled} />
      </form>
    );
  }
}

Field.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
};

Field.defaultProps = {
  min: undefined,
  max: undefined,
};

function Field({ value, label, enabled, onChange, min, max }) {
  return (
    <NumberInput
      value={value}
      unit="cm"
      styles={simpleInputStyles}
      onChange={onChange}
      placeholder={label}
      aria-label={label}
      disabled={!enabled}
      min={min}
      max={max}
    />
  );
}
