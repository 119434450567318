import PropTypes from "prop-types";
import React from "react";

import HorizontalScroller from "../HorizontalScroller";
import styles from "./styles.scss";

AttachmentInputArea.propTypes = {
  title: PropTypes.string,
  titleRight: PropTypes.node,
  scrollable: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

AttachmentInputArea.defaultProps = {
  title: undefined,
  titleRight: undefined,
  scrollable: false,
};

export default function AttachmentInputArea({
  title,
  titleRight,
  scrollable,
  children,
}) {
  return (
    <div className={styles.root}>
      {(title != null || titleRight != null) && (
        <h2
          className={
            scrollable ? styles.headingScrollable : styles.headingRegular
          }
        >
          {scrollable && titleRight != null && (
            <span className={styles.headingSpacer}>{titleRight}</span>
          )}

          {title}

          {titleRight != null && (
            <span className={styles.headingRight}>{titleRight}</span>
          )}
        </h2>
      )}

      {scrollable ? (
        <HorizontalScroller
          styles={{
            root: styles.contentScrollable,
            hasArrows: styles.hasArrows,
            arrow: styles.arrow,
          }}
        >
          <div className={styles.contentScrollableInner}>
            <div className={styles.contentScrollablePadder}>{children}</div>
          </div>
        </HorizontalScroller>
      ) : (
        <div className={styles.contentRegular}>{children}</div>
      )}
    </div>
  );
}
