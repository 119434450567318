import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import Link, { linkPropType } from "../Link";
import styles from "./styles.scss";

const IS_EXTERNAL = /^https?:\/\//;

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(["primary", "ghost", "footer", "yellow"]),
  boxShadow: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: linkPropType,
  // ..rest
};

Button.defaultProps = {
  className: "",
  theme: "primary",
  disabled: false,
  boxShadow: false,
  block: false,
  link: undefined,
};

export default function Button({
  className: passedClassName,
  children,
  theme,
  disabled,
  boxShadow,
  block,
  link,
  ...rest
}) {
  const className = classnames(
    styles.root,
    {
      [styles.boxShadow]: boxShadow,
      [styles.disabled]: disabled,
      [styles.block]: block,
    },
    styles[theme],
    passedClassName,
  );

  return link == null ? (
    <button type="button" {...rest} className={className} disabled={disabled}>
      {children}
    </button>
  ) : typeof link === "string" ? (
    <a
      href={link}
      {...rest}
      className={className}
      {...(IS_EXTERNAL.test(link)
        ? {
            target: "_blank",
            rel: "noopener noreferrer",
          }
        : {})}
    >
      {children}
    </a>
  ) : (
    <Link {...link}>
      <a {...rest} className={className}>
        {children}
      </a>
    </Link>
  );
}
