import classnames from "classnames";
import { Node, djedi, md } from "djedi-react";
import _ from "lodash";
import { withRouter } from "next/router";
import PropTypes from "prop-types";
import React from "react";
import { Collapse } from "react-collapse";

import * as api from "../../api";
import Blueprint, { blueprintProps } from "../../components/Blueprint";
import Button from "../../components/Button";
import Conversation, { itemIdToDomId } from "../../components/Conversation";
import DotsSpinner from "../../components/DotsSpinner";
import HelpPopup from "../../components/HelpPopup";
import Icon from "../../components/Icon";
import Layout from "../../components/Layout";
import Modal from "../../components/Modal";
import navStyles from "../../components/Nav/styles.scss";
import Timeline from "../../components/Timeline";
import ChevronDownIcon from "../../icons/chevron-down.svg";
import CrossIcon from "../../icons/cross.svg";
import DocumentsIcon from "../../icons/documents.svg";
import HistoryIcon from "../../icons/history.svg";
import avatar from "../../images/avatar.jpg";
import * as events from "../../main/events";
import { Sentry } from "../../sentry";
import { addQueryString } from "../../utils";
import { accessibilityFocus } from "../../utils/dom";
import { scrollElementToBottom } from "../../utils/scroll";
import Attachment from "./Attachment";
import HelpText from "./HelpText";
import { OPTIMISTIC_REPLY_ID, PRODUCT_TYPES_API } from "./constants";
import helpNodes from "./help-nodes";
import styles from "./styles.scss";
import {
  makeOptimisticReplyTo,
  remakeAttachmentReplyData,
  transformMessage,
} from "./utils";

const ROOM_IMAGE_WIDTH = 50; // px

// When autofocusing inputs, scroll down after the onscreen keyboard (such as on
// phones) has appeared so that the user can see the input.
const ONSCREEN_KEYBOARD_OPEN_DURATION = 200; // ms

const TEXT_INPUT_TYPES = ["tel", "text", "email", "number"];
const TEXT_INPUT_SELECTOR = TEXT_INPUT_TYPES.map(
  type => `input[type="${type}"]`,
)
  .concat("textarea")
  .join(", ");

const NUM_PAYMENT_MONTHS = 12;

const EMPTY_STATE = {
  messages: {
    fetching: true,
    error: undefined,
    data: undefined,
  },
  summary: {
    // Defaulting this to `true` rather than `false` avoids flashing the
    // ”sidebar empty” message until we’ve fetched the first summary. `messages`
    // above have also has `fetching: true` for consistency.
    fetching: true,
    error: undefined,
    data: undefined,
  },
  sidebarMobileExpanded: false,
  openSidebarSectionsByRoomKey: {},
  resetModalOpen: false,
  undoing: false,
};

class Chat extends React.Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
  };

  messagesScrollElement = undefined;
  inputAreaElement = undefined;
  debouncedScrollToBottom = _.debounce(
    this.scrollToBottom.bind(this, { instant: true }),
    50,
  );

  chatId = {
    value: "current",
    fromQueryString: false,
  };

  state = EMPTY_STATE;

  componentDidMount() {
    // "Secret" debugging URL parameters:
    //
    // - `reset`: reset the current session.
    // - `id=<chatId>`: use an already existing session rather than "current".
    //
    // Using another ID than the "current" one only works if you are logged in
    // as a superuser, and seems to be read-only.
    //
    // Use `id=current` to find out the current ID. `current` will be replaced
    // with the ID in question.
    if (typeof URLSearchParams !== "undefined") {
      const params = new URLSearchParams(window.location.search);

      const reset = params.get("reset") != null;
      const chatId = params.get("id");

      if (reset) {
        params.delete("reset");
        replaceQueryString(params.toString(), this.props.router);
      }

      if (chatId != null) {
        this.chatId = {
          value: chatId,
          fromQueryString: true,
        };
      }

      this.fetchMessages({ reset: reset ? true : undefined });
    } else {
      this.fetchMessages();
    }

    if (DEBUG) {
      window.chat = this;

      console.info(
        "Tip: You can play with `window.chat` on this page (try `window.chat.state`!).",
      );
    }
  }

  undo(messageId) {
    if (this.state.messages.data == null) {
      return;
    }

    const messages = this.state.messages.data.results;

    const messageIndex = messages.findIndex(item => item.id === messageId);

    if (messageIndex === -1) {
      console.warn("undo: Cannot find message", { messageId, messages });
      return;
    }

    const botMessageIndex = _.findLastIndex(
      messages,
      item => item.isBot,
      messageIndex,
    );

    if (botMessageIndex === -1) {
      console.warn("undo: No previous bot messages", {
        messageId,
        messageIndex,
        messages,
      });
      return;
    }

    const previousMessageIndex = _.findLastIndex(
      messages,
      item => !item.isBot,
      botMessageIndex,
    );

    const previousBotMessageIndex =
      previousMessageIndex === -1
        ? -1
        : _.findLastIndex(messages, item => item.isBot, previousMessageIndex);

    // Immediately slice off the undone messages for better UX.
    this.setState(state => ({
      messages: {
        ...state.messages,
        data: {
          ...state.messages.data,
          results: messages.slice(0, botMessageIndex + 1),
        },
      },
    }));

    // If we end up on the first question, persist the undo by resetting the
    // session. `this.resetSession()` is not used since it resets _all_ state as
    // well, which causes the page to jump unnecessarily.
    if (previousBotMessageIndex === -1) {
      this.setState(state => ({
        ...EMPTY_STATE,
        messages: state.messages,
        summary: {
          ...EMPTY_STATE.summary,
          // Avoid the sidebar blinking.
          fetching: false,
        },
        undoing: true,
      }));
      this.fetchMessages({ reset: true });
      return;
    }

    // Finally, try to persist the undo by answering the question before the one
    // we want to end up on again.

    const previousBotMessage = messages[previousBotMessageIndex];
    const attachment = previousBotMessage.attachments[0];

    if (attachment == null) {
      console.warn(
        "undo: previousBotMessage has no attachments",
        previousBotMessage,
      );
      return;
    }

    const data = remakeAttachmentReplyData(attachment);

    if (data == null) {
      console.warn("undo: Failed to remakeAttachmentReplyData", attachment);
      return;
    }

    this.setState({ undoing: true });
    this.replyTo(previousBotMessage, undefined, data);
  }

  resetSession() {
    this.setState(EMPTY_STATE);
    return this.fetchMessages({ reset: true });
  }

  onEvent(event) {
    events.chat.emit(event.name, event.data);
  }

  fetchMessages({
    reset = undefined,
    instantScroll: passedInstantScroll = undefined,
  } = {}) {
    const wasEmpty = this.state.messages.data == null;
    const instantScroll =
      passedInstantScroll == null ? wasEmpty : passedInstantScroll;

    // The “You already have a session” modal is super annoying during
    // development. Add `?enable_modal` to the URL to test it.
    const modalEnabled =
      !DEBUG || /[?&]enable_modal(?=&|$)/i.test(window.location.search);

    this.setState(state => ({
      messages: { ...state.messages, fetching: true },
    }));

    api
      .getChatMessages({ id: this.chatId.value }, { reset })
      .then(
        passedMessagesData => {
          const messagesData = {
            ...passedMessagesData,
            results: passedMessagesData.results.map(message =>
              transformMessage(message),
            ),
          };

          if (this.chatId.fromQueryString) {
            const chatId = passedMessagesData.results[0].session_id;
            const params = new URLSearchParams(window.location.search);
            params.set("id", chatId);
            replaceQueryString(params.toString(), this.props.router);
          }

          this.setState(
            state => ({
              ...state,
              messages: {
                ...state.messages,
                fetching: false,
                error: undefined,
                data: messagesData,
              },
              summary: { ...state.summary, fetching: true },
              resetModalOpen:
                modalEnabled &&
                wasEmpty &&
                !messagesData.session_created &&
                messagesData.results.some(message => !message.isBot),
            }),
            () => {
              this.scrollToBottom({ instant: instantScroll });
              this.autoFocus();
            },
          );

          const messageEvents = messagesData.events || [];
          messageEvents.forEach(event => this.onEvent(event));

          // The summary is fetched _after_ the messages since fetching messages
          // can also have the side effects of creating a new session or resetting
          // a session.
          return api.getChatSummary({ id: this.chatId.value }).then(
            passedSummaryData => {
              this.setState(state => {
                const summaryData = {
                  ...passedSummaryData,
                  rooms: passedSummaryData.rooms.map(room => ({
                    ...room,
                    key: `${room.name}-${room.position}`,
                  })),
                };

                return {
                  ...state,
                  summary: {
                    ...state.summary,
                    fetching: false,
                    error: undefined,
                    data: summaryData,
                  },
                  undoing: false,
                };
              });
            },
            error => {
              this.setState(
                state => ({
                  summary: { ...state.summary, error, fetching: false },
                  undoing: false,
                }),
                () => {
                  this.autoFocus();
                },
              );
            },
          );
        },
        error => {
          this.setState(
            state => ({
              messages: { ...state.messages, error, fetching: false },
              undoing: false,
            }),
            () => {
              this.autoFocus();
            },
          );
        },
      )
      .catch(error => {
        console.error("fetchMessages: Uncaught error", error);
        Sentry.captureException(error);
      });
  }

  replyTo(message, attachment, data) {
    const { undoing } = this.state;

    const reply = attachment
      ? makeOptimisticReplyTo(message, attachment, data)
      : null;

    if (reply != null) {
      this.setState(appendMessage.bind(undefined, reply));
    }

    this.setState(
      state => ({ messages: { ...state.messages, fetching: true } }),
      () => {
        this.scrollToBottom();
        this.autoFocus();
      },
    );

    api
      .createChatMessage({
        reply_to: message.id,
        data,
      })
      .then(
        response => {
          if (response != null && response.text != null) {
            this.setState(
              replaceOptimisticMessage.bind(
                undefined,
                transformMessage(response),
              ),
              () => {
                this.scrollToBottom();
              },
            );
          }
          return this.fetchMessages({ instantScroll: undoing });
        },
        error => {
          this.setState(
            state => ({
              messages: {
                ...state.messages,
                error,
                fetching: false,
              },
              undoing: false,
            }),
            () => {
              this.autoFocus();
            },
          );
        },
      );
  }

  scrollToBottom({ instant = false } = {}) {
    if (this.messagesScrollElement == null) {
      return;
    }

    scrollElementToBottom(this.messagesScrollElement, {
      duration: instant ? 0 : undefined,
    });
  }

  autoFocus() {
    if (this.inputAreaElement == null) {
      return;
    }

    const input = this.inputAreaElement.querySelector(TEXT_INPUT_SELECTOR);

    const autoFocusElement = input || this.inputAreaElement;

    if (autoFocusElement != null) {
      accessibilityFocus(autoFocusElement);

      if (input != null) {
        setTimeout(() => {
          this.scrollToBottom();
        }, ONSCREEN_KEYBOARD_OPEN_DURATION);
      }
    }
  }

  render() {
    const {
      messages,
      summary,
      sidebarMobileExpanded,
      openSidebarSectionsByRoomKey,
      resetModalOpen,
      undoing,
    } = this.state;

    const botMessages =
      messages.data == null
        ? []
        : messages.data.results.filter(message => message.isBot);
    const lastBotMessage =
      botMessages.length === 0
        ? undefined
        : botMessages[botMessages.length - 1];

    const items = [
      ...(messages.data == null
        ? []
        : [].concat(
            ...messages.data.results
              .filter(message => message.text.trim() !== "")
              .map(message =>
                [
                  {
                    id: message.id,
                    right: !message.isBot,
                    children: (
                      <div
                        className={styles.messageText}
                        dangerouslySetInnerHTML={{
                          __html: message.text || "\u00a0",
                        }}
                      />
                    ),
                    helpName: message.helpName,
                  },
                  message.errors.length > 0
                    ? {
                        id: `error-${message.id}`,
                        right: !message.isBot,
                        children: (
                          <div className={styles.messageText}>
                            {message.errors.map((errorMessage, errorIndex) => (
                              <p key={errorIndex}>{errorMessage}</p>
                            ))}
                          </div>
                        ),
                      }
                    : undefined,
                ].filter(Boolean),
              ),
          )),

      messages.error == null
        ? undefined
        : {
            id: "error",
            right: false,
            children: (
              <div>
                <p>Ett fel uppstod 😞</p>
                {DEBUG && (
                  <pre style={{ fontSize: 12 }}>{`${String(
                    messages.error,
                  )}\n\n${
                    messages.error == null ||
                    messages.error.responseText == null
                      ? "(No response text available.)"
                      : String(messages.error.responseText)
                  }`}</pre>
                )}
              </div>
            ),
          },

      messages.fetching && !undoing
        ? {
            id: "spinner",
            right: false,
            children: <DotsSpinner />,
          }
        : undefined,
    ].filter(Boolean);

    const lastLeftIndex = _.findLastIndex(items, item => !item.right);
    const lastRightIndex = _.findLastIndex(items, item => item.right);

    const itemsWithExtra = items.map((item, index) => {
      if (index === lastRightIndex) {
        return {
          ...item,
          image: (
            <UndoButton
              enabled={!messages.fetching}
              onClick={() => {
                this.undo(item.id);
              }}
            />
          ),
        };
      }

      if (index > lastRightIndex) {
        return {
          ...item,
          children:
            item.helpName == null ? (
              item.children
            ) : (
              <>
                {item.children}
                <div className={styles.helpButton}>
                  {helpNodes[item.helpName] == null ? (
                    DEBUG ? (
                      <div
                        style={{ color: "red" }}
                        title={`Unknown help name: ${item.helpName}`}
                      >
                        <Icon icon={CrossIcon} />
                      </div>
                    ) : null
                  ) : (
                    React.cloneElement(helpNodes[item.helpName], {
                      render: state => (
                        <HelpPopup childrenKey={state.type}>
                          {({ adjustPopupPosition }) => (
                            <HelpText onToggle={adjustPopupPosition}>
                              {djedi.options.defaultRender(state)}
                            </HelpText>
                          )}
                        </HelpPopup>
                      ),
                    })
                  )}
                </div>
              </>
            ),
          image: index === lastLeftIndex ? avatar : undefined,
        };
      }

      return item;
    });

    const lastItem =
      itemsWithExtra.length > 0
        ? itemsWithExtra[itemsWithExtra.length - 1]
        : undefined;

    const timelineNumbers =
      messages.data == null
        ? []
        : messages.data.results
            .map(message => message.timeline)
            .filter(number => number != null);
    const timeline =
      timelineNumbers.length === 0
        ? 1
        : timelineNumbers[timelineNumbers.length - 1];

    return (
      <Layout title="Chatbot" transparentNav={false} fixedNav>
        <Modal
          isOpen={resetModalOpen}
          title="Existerande chatt"
          padded
          onRequestClose={() => this.setState({ resetModalOpen: false })}
        >
          <p>
            Du har redan startat en chatt.
            <br />
            Vill du fortsätta eller börja om?
          </p>
          <div className={styles.resetModalButtons}>
            <Button onClick={() => this.setState({ resetModalOpen: false })}>
              Fortsätt
            </Button>
            <Button onClick={this.resetSession.bind(this)}>Börja om</Button>
          </div>
        </Modal>

        <div className={styles.root}>
          <div className={styles.main}>
            <div className={styles.messages}>
              <div className={styles.messagesInner}>
                <aside className={styles.timeline}>
                  <div className={styles.timelineInner}>
                    <Timeline timeline={timeline} />

                    {lastBotMessage &&
                      lastBotMessage.data &&
                      lastBotMessage.data.show_contact_me && (
                        <Button
                          className={styles.contactMe}
                          boxShadow
                          theme="yellow"
                          onClick={() => {
                            this.replyTo(lastBotMessage, null, {
                              change_path: "contact_me",
                            });
                          }}
                        >
                          <span>Behöver du hjälp?</span>
                        </Button>
                      )}
                  </div>
                </aside>

                <div className={styles.messagesScrollWrapper}>
                  <div
                    className={styles.messagesScroll}
                    ref={element => {
                      this.messagesScrollElement = element;
                    }}
                  >
                    <Conversation items={itemsWithExtra} />
                  </div>
                </div>
              </div>
            </div>

            {/* Always render the input area, even if it will be empty, for
            styling consistency. */}
            <div
              className={styles.inputArea}
              aria-labelledby={
                lastItem == null ? undefined : itemIdToDomId(lastItem.id)
              }
              ref={element => {
                this.inputAreaElement = element;
              }}
              // Scroll down when images load (which usually cause the input
              // area to grow taller).
              onLoad={() => {
                this.debouncedScrollToBottom();
              }}
            >
              {messages.data == null ? null : lastBotMessage == null ? (
                <div className={styles.inputAreaCentered}>
                  <p>Det gick inte kontakta chatboten 😞</p>
                  <p>Testa att ladda om sidan.</p>
                </div>
              ) : messages.fetching && undoing ? (
                <div className={styles.inputAreaCentered}>
                  <DotsSpinner />
                </div>
              ) : (
                lastBotMessage.attachments.map((attachment, index) => (
                  <Attachment
                    // The `key` here contains the message ID to force a
                    // remount when the last message changes, clearing all
                    // previous state.
                    key={`${lastBotMessage.id}-${index}`}
                    attachment={attachment}
                    enabled={!messages.fetching}
                    onSubmit={data => {
                      this.replyTo(lastBotMessage, attachment, data);
                    }}
                    onAutofocusNeeded={() => {
                      this.autoFocus();
                    }}
                    onScrollToBottomNeeded={() => {
                      this.scrollToBottom();
                    }}
                  />
                ))
              )}
            </div>
          </div>

          <aside
            className={classnames(styles.sidebar, {
              [styles.sidebarMobileExpanded]: sidebarMobileExpanded,
            })}
          >
            {summary.data != null && summary.data.rooms.length > 0 ? (
              <>
                <div className={styles.sidebarHeader}>
                  <div className={navStyles.itemWrapper}>
                    <h2 className={navStyles.item}>Din offert</h2>
                  </div>
                </div>
                <div className={styles.sidebarRooms}>
                  <div className={styles.sidebarRoomsScroll}>
                    {summary.data.rooms.map(room => {
                      const {
                        [room.key]: open = true,
                      } = openSidebarSectionsByRoomKey;
                      return (
                        <div key={room.key}>
                          <RoomTitle
                            room={room}
                            open={open}
                            onClick={() => {
                              this.setState(
                                toggleSidebarSection.bind(undefined, room.key),
                              );
                            }}
                          />
                          <Collapse isOpened={open} hasNestedCollapse>
                            <Room room={room} />
                          </Collapse>
                        </div>
                      );
                    })}

                    <DiscountTip />
                  </div>
                </div>
              </>
            ) : // Hide when fetching the summary the first time in case the user
            // returns to an existing chat.
            !(summary.data == null && summary.fetching && !undoing) ? (
              <div className={styles.emptySidebarWrapper}>
                <div className={styles.emptySidebarWrapperScroll}>
                  <div className={styles.emptySidebar}>
                    <Icon
                      icon={DocumentsIcon}
                      className={styles.emptySidebarIcon}
                    />
                    <h2 className={navStyles.item}>Din offert</h2>
                    <p>Här kommer dina valda produkter synas.</p>
                  </div>
                  <DiscountTip />
                </div>
              </div>
            ) : null}
            <div
              className={styles.sidebarBottom}
              onClick={() => {
                this.setState({
                  sidebarMobileExpanded: !sidebarMobileExpanded,
                });
              }}
            >
              {summary.data != null && summary.data.price_rot != null && (
                <div className={styles.priceContainer}>
                  {/* The spinner is only shown when the price becomes
                    available but is doesn't really matter. */}
                  <Price
                    priceWithROT={summary.data.price_rot}
                    priceWithoutROT={summary.data.price}
                    loading={summary.fetching}
                    expanded={sidebarMobileExpanded}
                  />
                </div>
              )}

              {summary.error != null && (
                <div className={styles.sidebarBottomPadding}>
                  <p>Det gick inte hämta pris 😞</p>
                  {DEBUG && (
                    <pre style={{ fontSize: 12 }}>{`${String(
                      summary.error,
                    )}\n\n${
                      summary.error == null ||
                      summary.error.responseText == null
                        ? "(No response text available.)"
                        : String(summary.error.responseText)
                    }`}</pre>
                  )}
                </div>
              )}
            </div>
          </aside>
        </div>
      </Layout>
    );
  }
}

export default withRouter(Chat);

function updateMessages(fn, state) {
  const { messages } = state;

  return {
    messages:
      messages.data == null
        ? undefined
        : {
            ...messages,
            data: {
              ...messages.data,
              results: fn(messages.data.results),
            },
          },
  };
}

function appendMessage(newMessage, state) {
  return updateMessages(messages => messages.concat(newMessage), state);
}

function replaceOptimisticMessage(newMessage, state) {
  return appendMessage(
    newMessage,
    updateMessages(
      messages =>
        messages.filter(message => message.id !== OPTIMISTIC_REPLY_ID),
      state,
    ),
  );
}

function toggleSidebarSection(roomName, state) {
  const { [roomName]: open = true } = state.openSidebarSectionsByRoomKey;
  return {
    openSidebarSectionsByRoomKey: {
      ...state.openSidebarSectionsByRoomKey,
      [roomName]: !open,
    },
  };
}

function replaceQueryString(queryString, router) {
  const newPath = addQueryString(window.location.pathname, queryString);
  window.history.replaceState(
    {
      url: router.pathname,
      as: newPath,
      options: {},
    },
    "",
    newPath,
  );
}

const pricePropType = PropTypes.shape({
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
});

Price.propTypes = {
  priceWithROT: pricePropType.isRequired,
  priceWithoutROT: pricePropType,
  loading: PropTypes.bool,
  expanded: PropTypes.bool,
};

Price.defaultProps = {
  priceWithoutROT: undefined,
  loading: false,
  expanded: false,
};

function Price({ priceWithROT, priceWithoutROT, loading, expanded }) {
  return (
    <div
      className={classnames(styles.price, styles.sidebarBottomPadding, {
        [styles.priceExpanded]: expanded,
      })}
    >
      <div className={styles.priceTitleWrapper}>
        <p className={styles.priceTitle}>
          <strong>Ditt pris just nu</strong>
        </p>
        <p>Monterat & klart</p>
      </div>

      {loading && (
        <div className={styles.priceSpinner}>
          <DotsSpinner />
        </div>
      )}

      <div className={styles.priceAmounts}>
        <p className={styles.priceAmountWithROT}>
          {formatPrice(priceWithROT)} inkl. ROT
        </p>

        <p className={styles.priceAmountPerMonth}>
          {formatPrice({
            ...priceWithROT,
            amount: Math.ceil(priceWithROT.amount / NUM_PAYMENT_MONTHS),
          })}{" "}
          /mån i {NUM_PAYMENT_MONTHS} mån*
        </p>

        {priceWithoutROT != null && (
          <p className={styles.priceAmountWithoutROT}>
            {formatPrice(priceWithoutROT)} exkl. ROT
          </p>
        )}
      </div>

      <div className={styles.priceDisclaimer}>
        <Node uri="chat/payment.md">{md`
          \\\* Uppläggningsavgift 295:- och aviavgift 39:- tillkommer.
          Läs mer om delbetalning i <a href="/villkor" target="_blank">villkor</a>.
        `}</Node>
      </div>

      <Icon icon={ChevronDownIcon} className={styles.priceChevron} />
    </div>
  );
}

function formatPrice({ amount, currency }) {
  const formattedAmount = amount.toLocaleString("sv");
  return currency === "SEK"
    ? `${formattedAmount}:-`
    : `${formattedAmount} ${currency}`;
}

RoomTitle.propTypes = {
  room: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

RoomTitle.defaultProps = {
  open: false,
};

function RoomTitle({ room, open, onClick }) {
  return (
    <button
      type="button"
      className={classnames(styles.roomTitle, { [styles.roomOpen]: open })}
      onClick={() => {
        onClick();
      }}
    >
      <div className={styles.roomTitleInner}>
        <div>
          <span className={styles.roomHeading}>{room.name}</span>{" "}
          {room.name !== room.position ? (
            <span className={styles.roomPosition}>{room.position}</span>
          ) : (
            undefined
          )}
        </div>

        <div className={styles.roomTitleRight}>
          <span className={styles.roomCount}>{room.configurations.length}</span>{" "}
          <Icon icon={ChevronDownIcon} className={styles.roomChevron} />
        </div>
      </div>
    </button>
  );
}

class Room extends React.Component {
  static propTypes = {
    room: PropTypes.object.isRequired,
  };

  state = {
    openConfInfoByConfKey: {},
  };

  render() {
    const { room } = this.props;
    const { openConfInfoByConfKey } = this.state;

    return (
      <ul className={styles.room}>
        {room.configurations.map(conf => {
          // The current window is supposed to be open by default.
          const { current = false } = conf;
          const { [conf.title]: open = current } = openConfInfoByConfKey;
          const info = [
            ["Serie", conf.family],
            ["Hängning", conf.hinge],
            ["Modell", conf.frame],
            ["Spröjs", conf.sash && conf.sash.name],
            ["Ytbehandling", conf.alu_coating || conf.wood_coating],
            [
              "Mått",
              conf.width == null || conf.height == null
                ? undefined
                : `${conf.width}×${conf.height} ${conf.dimension_unit || "cm"}`,
            ],
            ["Glas", conf.glass],
            ["Ytbehandling (insidan)", conf.interior],
            ["Ytbehandling (utsidan)", conf.finish],
            ["Fjärrstyrning", conf.control_mode],
            ["Typ", conf.panel],
          ].filter(([, value]) => value != null);

          const renderBlueprint =
            [
              PRODUCT_TYPES_API.WINDOW,
              PRODUCT_TYPES_API.WINDOW_DOOR,
              PRODUCT_TYPES_API.DOOR,
            ].includes(conf.product_type) &&
            !(
              conf.product_type === PRODUCT_TYPES_API.DOOR && conf.images.FRAME
            );
          const sashDefaults =
            renderBlueprint && getSashDimensionDefaults(conf);

          return (
            <li
              key={conf.title}
              className={classnames(styles.roomConf, {
                [styles.roomOpen]: open,
              })}
            >
              <div className={styles.roomConfBlueprint}>
                {!renderBlueprint ? (
                  <img
                    width={ROOM_IMAGE_WIDTH}
                    src={conf.images.FRAME}
                    alt=""
                  />
                ) : (
                  <Blueprint
                    svgWidth={ROOM_IMAGE_WIDTH}
                    coloredGlass={conf.product_type !== PRODUCT_TYPES_API.DOOR}
                    {...blueprintProps(
                      {
                        ...conf.sash,
                        width:
                          conf.sash.width == null
                            ? sashDefaults.width
                            : conf.sash.width,
                        height:
                          conf.sash.height == null
                            ? sashDefaults.height
                            : conf.sash.height,
                      },
                      // Render doors as window doors with only a mid rail and
                      // no glass.
                      conf.product_type === PRODUCT_TYPES_API.DOOR
                        ? { glass_height: 0 }
                        : conf.parapet,
                    )}
                  />
                )}
              </div>

              <div className={styles.roomConfInfo}>
                <p className={styles.roomHeading}>{conf.title}</p>

                {info.length > 0 && (
                  <Collapse isOpened={open}>
                    {info.map(([label, value]) => (
                      <p key={label}>
                        {label}:{" "}
                        <span className={styles.roomConfInfoValue}>
                          {value}
                        </span>
                      </p>
                    ))}
                    <div className={styles.roomConfInfoSpacer} />
                  </Collapse>
                )}

                {info.length > 1 && (
                  <button
                    type="button"
                    className={styles.roomConfToggle}
                    onClick={() => {
                      this.setState(state => ({
                        openConfInfoByConfKey: {
                          ...state.openConfInfoByConfKey,
                          [conf.title]: !open,
                        },
                      }));
                    }}
                  >
                    <span>{open ? "Dölj detaljer" : "Visa detaljer"}</span>
                    <Icon
                      icon={ChevronDownIcon}
                      className={styles.roomChevron}
                    />
                  </button>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    );
  }
}

function getSashDimensionDefaults(conf) {
  switch (conf.product_type) {
    case PRODUCT_TYPES_API.WINDOW:
      return {
        width: 1000,
        height: 1000,
      };

    case PRODUCT_TYPES_API.WINDOW_DOOR:
      return {
        width: 1000,
        height: 2000,
      };

    case PRODUCT_TYPES_API.DOOR:
      return {
        width: 1000,
        height: 2000,
      };

    default:
      console.warn("Unknown product type", conf.product_type);
      return {
        width: 1000,
        height: 1000,
      };
  }
}

UndoButton.propTypes = {
  enabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

UndoButton.defaultProps = {
  enabled: true,
};

function UndoButton({ enabled, onClick }) {
  return (
    <button
      type="button"
      className={styles.undoButton}
      title="Ångra val"
      disabled={!enabled}
      onClick={() => {
        onClick();
      }}
    >
      <div className={styles.undoButtonInner}>
        <Icon icon={HistoryIcon} className={styles.undoButtonIcon} />
        <span className={styles.undoButtonLabel}>Ändra</span>
      </div>
    </button>
  );
}

function DiscountTip() {
  return (
    <div className={styles.discountTip}>
      <p className={styles.discountTipCircle}>TIPS!</p>
      <p>
        Ju fler fönster eller dörrar du beställer, desto mer rabatt får du på
        slutpriset.
      </p>
    </div>
  );
}
