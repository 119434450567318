import PropTypes from "prop-types";
import React from "react";

import { FIELD_TYPES } from "../../pages-helpers/chat/constants";
import NumberInput from "../NumberInput";
import SendButton from "../SendButton";
import SimpleInput from "../SimpleInput";
import simpleInputStyles from "../SimpleInput/styles.scss";
import styles from "./styles.scss";

export default class FormAttachment extends React.Component {
  static propTypes = {
    fields: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.oneOf(Object.values(FIELD_TYPES)).isRequired,
          name: PropTypes.string.isRequired,
          // and other fields
        }).isRequired,
      ).isRequired,
    ).isRequired,
    enabled: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    enabled: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      fieldValuesByName: [].concat(...props.fields).reduce((result, field) => {
        result[field.name] = field.value;
        return result;
      }, {}),
    };

    this.idBase = `FormAttachment-${Math.random()}`;
  }

  // One _could_ implement this method to see if any of the fields have changed
  // and update state, but it’s not worth the trouble until we need it.
  // componentWillReceiveProps(nextProps) {
  // }

  render() {
    const { enabled, fields, onSubmit } = this.props;
    const { fieldValuesByName } = this.state;

    const lastIndex = fields.length - 1;

    return (
      <form
        className={styles.root}
        onSubmit={event => {
          event.preventDefault();
          if (enabled) {
            onSubmit(fieldValuesByName);
          }
        }}
      >
        {fields.map((row, index) => (
          <div key={index} className={styles.row}>
            {row.map(field => {
              const inputProps = {
                key: field.name,
                value: fieldValuesByName[field.name] || "",
                disabled: !enabled,
                invalid: field.errors != null && field.errors.length > 0,
                onChange: newValue => {
                  this.setState({
                    fieldValuesByName: {
                      ...fieldValuesByName,
                      [field.name]: newValue == null ? 0 : newValue,
                    },
                  });
                },
                styles: simpleInputStyles,
                style: { flexGrow: 1 },
              };

              function numberWrapper(node) {
                return (
                  <div key={inputProps.key} style={inputProps.style}>
                    {node}
                  </div>
                );
              }

              switch (field.type) {
                case FIELD_TYPES.CHAR_WITH_CHOICES: {
                  const id = `${this.idBase}-${field.name}`;
                  return (
                    <>
                      <SimpleInput {...inputProps} list={id} />
                      <datalist id={id}>
                        {field.choices.map(choice => (
                          <option key={choice} value={choice} />
                        ))}
                      </datalist>
                    </>
                  );
                }

                case FIELD_TYPES.INTEGER:
                  return numberWrapper(
                    <NumberInput
                      {...inputProps}
                      value={inputProps.value === "" ? 0 : inputProps.value}
                      min={field.minValue == null ? undefined : field.minValue}
                      max={field.maxValue == null ? undefined : field.maxValue}
                      placeholder="Ange antal…"
                    />,
                  );

                case FIELD_TYPES.PHONE:
                  return numberWrapper(
                    <NumberInput
                      {...inputProps}
                      placeholder="Ange ditt telefonnummer…"
                    />,
                  );

                case FIELD_TYPES.POSTAL_CODE:
                  return numberWrapper(
                    <NumberInput
                      {...inputProps}
                      placeholder="Ange ditt postnummer…"
                    />,
                  );

                case FIELD_TYPES.EMAIL:
                  return (
                    <SimpleInput
                      {...inputProps}
                      placeholder="Ange din e-postadress…"
                    />
                  );

                default:
                  return <SimpleInput {...inputProps} />;
              }
            })}

            {index === lastIndex && (
              <SendButton key="SendButton" disabled={!enabled} />
            )}
          </div>
        ))}
      </form>
    );
  }
}
