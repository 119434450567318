import PropTypes from "prop-types";
import React from "react";

import { FIELD_TYPES } from "../../pages-helpers/chat/constants";
import FormAttachment from "../FormAttachment";

export default class JsonAttachment extends React.Component {
  static propTypes = {
    data: PropTypes.any.isRequired,
    enabled: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    enabled: true,
  };

  state = {
    parseError: undefined,
  };

  render() {
    const { data, enabled, onSubmit } = this.props;
    const { parseError } = this.state;

    return (
      <div>
        <FormAttachment
          fields={[
            [
              {
                type: FIELD_TYPES.CHAR,
                name: "value",
                value: JSON.stringify(data, undefined, 1).replace(/\s+/g, " "),
                errors: [parseError].filter(Boolean),
              },
            ],
          ]}
          enabled={enabled}
          onSubmit={({ value }) => {
            let parsed = undefined;
            try {
              parsed = JSON.parse(value);
            } catch (error) {
              this.setState({ parseError: error });
              return;
            }
            onSubmit(parsed);
            this.setState({ parseError: undefined });
          }}
        />

        {parseError != null && <p>{parseError.message}</p>}
      </div>
    );
  }
}
