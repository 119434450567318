import PropTypes from "prop-types";
import React from "react";

import AttachmentInputArea from "../../components/AttachmentInputArea";
import ButtonsAttachment from "../../components/ButtonsAttachment";
import DayPicker from "../../components/DayPicker";
import FormAttachment from "../../components/FormAttachment";
import ImageChoicesAttachment from "../../components/ImageChoicesAttachment";
import JsonAttachment from "../../components/JsonAttachment";
import PositionsAttachment from "../../components/PositionsAttachment";
import SizeAttachment from "../../components/SizeAttachment";
import { toISODateString } from "../../utils";
import { ATTACHMENT_TYPES, BUTTONS_MODES } from "./constants";
import { getAllChoices, hasSeveralChoicesGroups } from "./utils";

Attachment.propTypes = {
  attachment: PropTypes.object.isRequired,
  enabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onAutofocusNeeded: PropTypes.func.isRequired,
  onScrollToBottomNeeded: PropTypes.func.isRequired,
  titleRight: PropTypes.node,
};

Attachment.defaultProps = {
  titleRight: null,
};

export default function Attachment({
  attachment,
  enabled,
  onSubmit,
  onAutofocusNeeded,
  onScrollToBottomNeeded,
  titleRight,
}) {
  const allChoices = getAllChoices(attachment.choices);
  if (allChoices != null && allChoices.length === 0) {
    return (
      <AttachmentInputArea title={attachment.title} titleRight={titleRight}>
        <p>
          <em>Inga val hittades.</em>
        </p>
      </AttachmentInputArea>
    );
  }

  switch (attachment.type) {
    case ATTACHMENT_TYPES.BLUEPRINTS: {
      const showTitle = !hasSeveralChoicesGroups(attachment.choices);
      return (
        <AttachmentInputArea
          title={showTitle ? attachment.title : undefined}
          titleRight={showTitle ? titleRight : undefined}
          scrollable
        >
          <ImageChoicesAttachment
            choices={attachment.choices}
            enabled={enabled}
            onSubmit={value => {
              onSubmit({ value });
            }}
            onScrollToBottomNeeded={onScrollToBottomNeeded}
          />
        </AttachmentInputArea>
      );
    }

    case ATTACHMENT_TYPES.BUTTONS: {
      const showTitle = !hasSeveralChoicesGroups(attachment.choices);
      return (
        <AttachmentInputArea
          title={showTitle ? attachment.title : undefined}
          titleRight={showTitle ? titleRight : undefined}
        >
          <ButtonsAttachment
            mode={attachment.mode}
            choices={attachment.choices}
            enabled={enabled}
            onSubmit={value => {
              onSubmit(
                attachment.mode === BUTTONS_MODES.CUSTOM_TEXT
                  ? { [attachment.name]: value }
                  : { value },
              );
            }}
            onAutofocusNeeded={onAutofocusNeeded}
            onScrollToBottomNeeded={onScrollToBottomNeeded}
          />
        </AttachmentInputArea>
      );
    }

    case ATTACHMENT_TYPES.DAY:
      return (
        <AttachmentInputArea
          title={attachment.title}
          titleRight={titleRight}
          scrollable
        >
          <DayPicker
            enabled={enabled}
            selectedDate={
              attachment.date == null ? undefined : new Date(attachment.date)
            }
            today={new Date(attachment.today)}
            // Disallow past dates, today, and tomorrow. This should probably be
            // validated in the backend as well.
            disabledFromTodayOffset={1}
            disabledDates={attachment.disabledDates.map(date => new Date(date))}
            disabledWeekdays={attachment.disabledWeekdays}
            onDayClick={date => {
              onSubmit({
                date: toISODateString(date),
              });
            }}
          />
        </AttachmentInputArea>
      );

    case ATTACHMENT_TYPES.FORM:
      return (
        <AttachmentInputArea title={attachment.title} titleRight={titleRight}>
          {attachment.fields.every(row => row.length === 0) ? (
            <p>
              <em>Inga fält hittades.</em>
            </p>
          ) : (
            <FormAttachment
              enabled={enabled}
              fields={attachment.fields}
              onSubmit={onSubmit}
            />
          )}
        </AttachmentInputArea>
      );

    case ATTACHMENT_TYPES.JSON: {
      return (
        <AttachmentInputArea title={attachment.title} titleRight={titleRight}>
          <JsonAttachment data={attachment.data} onSubmit={onSubmit} />
        </AttachmentInputArea>
      );
    }

    case ATTACHMENT_TYPES.POSITIONS:
      return (
        <AttachmentInputArea title={attachment.title} titleRight={titleRight}>
          <PositionsAttachment
            choices={attachment.choices}
            enabled={enabled}
            onClick={value => {
              onSubmit({ value });
            }}
          />
        </AttachmentInputArea>
      );

    case ATTACHMENT_TYPES.SIZE:
      return (
        <AttachmentInputArea title={attachment.title} titleRight={titleRight}>
          <SizeAttachment
            width={{
              defaultValue: attachment.width || attachment.suggestedWidth || 0,
            }}
            height={{
              defaultValue:
                attachment.height || attachment.suggestedHeight || 0,
            }}
            enabled={enabled}
            onSubmit={onSubmit}
          />
        </AttachmentInputArea>
      );

    default:
      console.error("Unknown attachment type", attachment.type, attachment);
      return null;
  }
}
