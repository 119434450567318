import { Node, md } from "djedi-react";
import React from "react";

/*
How to add a new help text:

1. Choose a name for it, such as “foo”.
2. Let the backend send `"help_name": "foo"` for the message(s) you want the
   help text for.
3. Add `foo: <Node uri="chat/help/foo.md" />,` in the object below. (Optionally
   with a default value for the Djedi node.)

Manually listing all nodes like this allows having a page (/chat/help-texts)
where all help texts are listed, which makes editing them easy. (No need to
first navigate to the exact question in the bot.)

If a new image is needed for a help text, put it in the `static/` folder.
`static/foo.jpg` will be available as `/static/foo.jpg`.
*/

export default {
  window_glass_second_floor_below_60: (
    <Node uri="chat/help/window_glass_second_floor_below_60.md">{md`
      ## Personskydd?

      Personsäkerhetsglas minskar risken för personskador så som skärskador och utfall om glaset skulle krossas.

      ### Härdat glas

      Ett härdat säkerhetsglas är 5 gånger starkare än ett vanligt glas och splittras med rundade kanter om det går sönder. Generellt gäller minst härdat glas, där det finns risk för att någon går in i glaset.

      <img src="/static/hardat-glas.jpg" width="90" alt="">

      ---

      ### Lamell / Laminerat glas

      Ett laminerat glas byggs upp av två eller flera glas, med en folie emellan. Om glaset krossas hålls det ihop i ett sprickmönster. Lamellglas bör användas där det finns risk för att någon går in i glaset och där det är risk för utfall.

      <img src="/static/laminerat-glas.jpg" width="90" alt="">

      ---

      ## Energiglas?

      Energiglas är ett glas med en beläggning på som bromsar långvarig värmestrålning så att värmen stannar kvar i rummet. I ett isolerglas kan ett eller flera av glasen vara energiglas. I de allra flesta av doowins produkter kan du välja energiglas som tillval, vilket förbättrar fönstrets isoleringsförmåga (sänker u-värdet).

      ## U-värde?

      U-värdet är ett mått på hela fönstrets värmeisolerande förmåga. Ju lägre U-värde, ju bättre isolerar fönstret och ju mindre blir uppvärmningsbehovet = du sparar både dina pengar och miljön! U-värdet varierar efter fönstrets storlek och modell. Ett U-värde omkring eller under 1 ger mycket bra isolering. E u-värde närmare 3 ger mindre isolering. På doowin har de flesta av våra fönsterserier ett u-värde runt 1.

      ## Ljudvärde?

      Ljudvärdet uppmäts i Decibel (dB). Ju högre ljudvärde, ju bättre dämpning av ljud och buller utifrån. Doowin har många effektiva lösningar för en bättre ljuddämpning/ljudreduktion. Ett högre ljudvärde kan uppnås genom val av glas och genom anpassningar av fönstrets konstruktion. Ljudruta standard ger ökad ljuddämpning jämfört med ett originalfönster och ljud-lamellruta ger ännu mer effektiv ljuddämpning.
    `}</Node>
  ),

  window_glass_first_floor_above_60: (
    <Node uri="chat/help/window_glass_first_floor_above_60.md">{md`
      ## Solskydd?

      Solskyddsglas släpper in lika mycket ljus som vanligt glas men stänger ute nästan två tredjedelar av den totala solvärmen. Solskyddsglas ger därmed ett jämnare inomhusklimat och minskar behovet av kylning. Suncool 70/40 är det vanligaste glaset för solskydd och lämpligt för villor och småhus, Suncool 66/33 är lämplig för större fönsterpartier i villor och kontorsmiljö och Suncool 50/25 bör användas när avskärmningsbehovet är mycket stort.

      ## Insynsskydd?

      Insynsskydd används där man vill ha in ljus, men skydda mot insyn. Den vanligaste placeringen är i badrummet, men behovet kan se olika ut. Finns som både Etsat, Marine och Cotswold.

      <figure>
        <img src="/static/etsat.jpg" width="130" alt="">
        <figcaption>Etsat</figcaption>
      </figure>
      <figure>
        <img src="/static/marine.jpg" width="130" alt="">
        <figcaption>Marine</figcaption>
      </figure>
      <figure>
        <img src="/static/cotswold.jpg" width="130" alt="">
        <figcaption>Cotswold</figcaption>
      </figure>

      ---

      ## Smutsavvisande glas?

      Smutsavvisande glas underlättar putsningen av dina fönster betydligt. Glasets speciella yta gör att smutsen löses upp av solljuset och när det regnar glider smutsen av. Utsätts fönstret för mycket sol och regn kan det därmed innebära att du inte behöver putsa alls.

      ## Energiglas?

      Energiglas är ett glas med en beläggning på som bromsar långvarig värmestrålning så att värmen stannar kvar i rummet. I ett isolerglas kan ett eller flera av glasen vara energiglas. I de allra flesta av doowins produkter kan du välja energiglas som tillval, vilket förbättrar fönstrets isoleringsförmåga (sänker u-värdet).

      ## U-värde?

      U-värdet är ett mått på hela fönstrets värmeisolerande förmåga. Ju lägre U-värde, ju bättre isolerar fönstret och ju mindre blir uppvärmningsbehovet = du sparar både dina pengar och miljön! U-värdet varierar efter fönstrets storlek och modell. Ett U-värde omkring eller under 1 ger mycket bra isolering. E u-värde närmare 3 ger mindre isolering. På doowin har de flesta av våra fönsterserier ett u-värde runt 1.

      ## Ljudvärde?

      Ljudvärdet uppmäts i Decibel (dB). Ju högre ljudvärde, ju bättre dämpning av ljud och buller utifrån. Doowin har många effektiva lösningar för en bättre ljuddämpning/ljudreduktion. Ett högre ljudvärde kan uppnås genom val av glas och genom anpassningar av fönstrets konstruktion. Ljudruta standard ger ökad ljuddämpning jämfört med ett originalfönster och ljud-lamellruta ger ännu mer effektiv ljuddämpning.
    `}</Node>
  ),

  window_glass_second_floor_bathroom: (
    <Node uri="chat/help/window_glass_second_floor_bathroom.md">{md`
      ## Personskydd?

      Personsäkerhetsglas minskar risken för personskador så som skärskador och utfall om glaset skulle krossas.

      ### Härdat glas

      Ett härdat säkerhetsglas är 5 gånger starkare än ett vanligt glas och splittras med rundade kanter om det går sönder. Generellt gäller minst härdat glas, där det finns risk för att någon går in i glaset.

      <img src="/static/hardat-glas.jpg" width="90" alt="">

      ---

      ### Lamell / Laminerat glas

      Ett laminerat glas byggs upp av två eller flera glas, med en folie emellan. Om glaset krossas hålls det ihop i ett sprickmönster. Lamellglas bör användas där det finns risk för att någon går in i glaset och där det är risk för utfall.

      <img src="/static/laminerat-glas.jpg" width="90" alt="">

      ---

      ## Insynsskydd?

      Insynsskydd används där man vill ha in ljus, men skydda mot insyn. Den vanligaste placeringen är i badrummet, men behovet kan se olika ut. Finns som både Etsat, Marine och Cotswold.

      <figure>
        <img src="/static/etsat.jpg" width="130" alt="">
        <figcaption>Etsat</figcaption>
      </figure>
      <figure>
        <img src="/static/marine.jpg" width="130" alt="">
        <figcaption>Marine</figcaption>
      </figure>
      <figure>
        <img src="/static/cotswold.jpg" width="130" alt="">
        <figcaption>Cotswold</figcaption>
      </figure>
    `}</Node>
  ),

  window_luft: (
    <Node uri="chat/help/window_luft.md">{md`
      ## Karm?

      Karmen är fönstrets ytterram.

      ## Båge?

      Bågen är fönstrets öppningsbara del och sitter fast i karmen med beslag och gångjärn.

      ## Luft?

      Fönsterluft är ett begrepp som avser en öppningsbar båge, omsluten av en karm. Sidohängda 1-luftsfönster har 1 båge, 2-luftsfönster har 2 bågar, o.s.v.
    `}</Node>
  ),

  window_sash: (
    <Node uri="chat/help/window_sash.md">{md`
      ## Spröjs?

      Spröjs är dekorativa och delar upp ditt fönster i ”mindre rutor” vilket ger ditt fönster mer karaktär.

      ### Löstagbar spröjs / Dekorspröjs

      Består av komposit, aluminium eller trä och knäpps fast på fönstrets utsida. De kan tas bort när fönstret ska putsas.

      <img src="/static/dekorsprojs.jpg" width="80" alt="">

      ---

      ### Mellanglasspröjs

      Mellanglasspröjs tillverkas av aluminium och sätts mellan glasen isolerrutan. De håller sig fina år efter år och är inte i vägen vid fönsterputsning.

      <img src="/static/mellanglassprojs.jpg" width="80" alt="">

      ---

      ### Retrospröjs

      Består av invändig fast spröjs i trä och utvändig fast spröjs i aluminium, samt distanser mellan glasen. Utseendet liknar klassiska glasdelande spröjs.

      <img src="/static/retrosprojs.jpg" width="80" alt="">

      ---

      ### Glasdelande poster?

      En post är bredare och ger lite kraftigare uttryck än spröjs. Poster tillverkas i trä eller aluminium. De är glasdelande och sitter i bågen på öppningsbara fönster och i karmen på fasta fönster. De kombineras ofta med dekorspröjs.

      <img src="/static/glasdelande-poster1.jpg" width="80" alt="">
      <img src="/static/glasdelande-poster2.jpg" width="80" alt="">

      ---
    `}</Node>
  ),

  window_size: (
    <Node uri="chat/help/window_size.md">{md`
      ## Mätanvisnigar

      <img src="/static/matanvisningar.jpg" alt="Mät bredden genom att mäta från insidan av ena ytterkarmen till insidan av andra ytterkarmen. Lägg till 10 cm till måttet. Mät höjden på samma sätt.">
    `}</Node>
  ),

  window_door_parapet: (
    <Node uri="chat/help/window_door_parapet.md">{md`
      ## Bröstning?

      Bröstning betyder att altandörren är uppdelad i två delar där den övre delen är av glas och den undre oftast består av trä. Väljer du en altandörr med bröstning bör bröstningen linjeras med övriga fönster i det rum altandörren sitter. Under vårt kostnadsfria hembesök hjälper vi dig med linjeringen.
    `}</Node>
  ),
  garage_door_width: (
    <Node uri="chat/help/garage_door_width.md">{md`
      ## Bredd

      Avvikande bredd och valfri kulör kan läggas till vid besöket för kontrollmätning.\* Välj det mått som ligger närmast.

      \*Ev avgift kan tillkomma.
    `}</Node>
  ),
  garage_door_height: (
    <Node uri="chat/help/garage_door_height.md">{md`
      ## Höjd

      Avvikande höjd och valfri kulör kan läggas till vid besöket för kontrollmätning.\* Välj det mått som ligger närmast.

      \*Ev avgift kan tillkomma.
    `}</Node>
  ),
};
