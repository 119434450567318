import PropTypes from "prop-types";
import React from "react";

import Text from "../../components/Text";
import styles from "./styles.scss";

export default class HelpText extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onToggle: PropTypes.func,
    collapse: PropTypes.bool,
  };

  static defaultProps = {
    onToggle: undefined,
    collapse: true,
  };

  rootRef = React.createRef();

  componentDidMount() {
    this.setupCollapse();
  }

  setupCollapse = () => {
    const { onToggle, collapse } = this.props;
    const root = this.rootRef.current;

    if (root != null && collapse) {
      setupCollapse(root, onToggle);
    }
  };

  render() {
    const { children } = this.props;

    return (
      <div className={styles.helpText} ref={this.rootRef}>
        <Text>{children}</Text>
      </div>
    );
  }
}

function setupCollapse(root, onToggle) {
  const headings = Array.from(root.querySelectorAll("h2, h3"));

  if (headings.length <= 1) {
    return;
  }

  for (const heading of headings) {
    const siblings = getSiblings(heading, headings);

    if (siblings.length > 0) {
      setupHeading(heading, siblings, onToggle);
    }
  }
}

function getSiblings(heading, headings) {
  const siblings = [];
  let current = heading;

  while (
    (current = current.nextElementSibling) &&
    !headings.includes(current)
  ) {
    siblings.push(current);
  }

  return siblings;
}

function setupHeading(heading, siblings, onToggle) {
  heading.setAttribute("role", "button");
  heading.setAttribute("tabindex", "0");
  heading.style.cursor = "pointer";

  let expanded = false;

  function onInteraction() {
    expanded = !expanded;
    toggle(heading, siblings, expanded);

    const last = siblings[siblings.length - 1];
    if (expanded && last != null) {
      last.scrollIntoView({ block: "nearest" });
    }
    if (onToggle != null) {
      onToggle();
    }
  }

  heading.onclick = onInteraction;
  heading.onkeydown = event => {
    if (event.key === "Enter" || event.key === " ") {
      onInteraction();
    }
  };

  toggle(heading, siblings, expanded);
}

function toggle(trigger, elements, show) {
  trigger.setAttribute("aria-expanded", String(show));
  for (const element of elements) {
    element.style.display = show ? "" : "none";
  }
}
