import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import ReactModal from "react-modal";

import CrossIcon from "../../icons/cross.svg";
import Icon from "../Icon";
import styles from "./styles.scss";

const appElement =
  typeof document === "undefined"
    ? undefined
    : document.getElementById("__next") ||
      // Storybook support.
      document.getElementById("root");

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  padded: PropTypes.bool,
  useDefaultLayout: PropTypes.bool,
  animationDuration: PropTypes.number,
  onAfterOpen: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

Modal.defaultProps = {
  padded: true,
  useDefaultLayout: true,
  animationDuration: 200, // ms
  onAfterOpen: undefined,
  children: undefined,
};

export default function Modal({
  isOpen,
  title,
  padded,
  useDefaultLayout,
  animationDuration,
  onAfterOpen,
  onRequestClose,
  children,
}) {
  const transitionDuration = `${animationDuration}ms`;
  return (
    <ReactModal
      isOpen={isOpen}
      closeTimeoutMS={animationDuration}
      appElement={appElement}
      contentLabel={title}
      bodyOpenClassName="is-modalOpen"
      portalClassName="Modal"
      overlayClassName={{
        base: styles.overlay,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      className={{
        base: styles.content,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      style={{
        overlay: { transitionDuration },
        content: { transitionDuration },
      }}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
    >
      {useDefaultLayout ? (
        <div>
          <h1 className={styles.title}>
            <span className={styles.titleText}>{title}</span>
            <button
              type="button"
              aria-label="Stäng"
              className={styles.close}
              onClick={() => {
                onRequestClose();
              }}
            >
              <Icon icon={CrossIcon} />
            </button>
          </h1>
          <div className={styles.inner}>
            <div className={classnames({ [styles.padded]: padded })}>
              {children}
            </div>
          </div>
        </div>
      ) : (
        children
      )}
    </ReactModal>
  );
}
