import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";

import InformationOutline from "../../icons/information-outline.svg";
import EventListener from "../EventListener";
import Icon from "../Icon";
import Modal from "../Modal";
import WithWindowSize from "../WithWindowSize";
import styles from "./styles.scss";

const FADE_DURATION = 200; // ms
const MOUSELEAVE_DELAY = 200; // ms
const OFFSET = 10; // px

const appElement =
  typeof document === "undefined"
    ? undefined
    : document.getElementById("__next") ||
      // Storybook support.
      document.getElementById("root");

export default class HelpPopup extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node.isRequired,
      PropTypes.func.isRequired,
    ]).isRequired,
    // If you change `children`, also change `childrenKey`. Then the popup will
    // re-adjust its position based on the new content.
    childrenKey: PropTypes.string,
  };

  static defaultProps = {
    childrenKey: undefined,
  };

  state = {
    open: false,
    stayOpen: false,
    closing: false,
    popupPosition: undefined,
    popupAdjusted: false,
  };

  buttonRef = React.createRef();
  popupRef = React.createRef();
  timeoutId = undefined;

  componentDidUpdate(prevProps) {
    if (this.state.popupPosition == null) {
      this.updatePopupPosition();
    } else if (
      !this.state.popupAdjusted ||
      this.props.childrenKey !== prevProps.childrenKey
    ) {
      this.adjustPopupPosition();
    }
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  clearTimeout = () => {
    if (this.timeoutId != null) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
  };

  updatePopupPosition = () => {
    const button = this.buttonRef.current;

    if (button == null) {
      return;
    }

    const rect = button.getBoundingClientRect();

    this.setState({
      popupPosition: {
        top: Math.round(rect.top + rect.height / 2),
        left: Math.round(rect.right),
      },
    });
  };

  // Keep the popup inside the viewport. `OFFSET` px from the edges.
  adjustPopupPosition = () => {
    const popup = this.popupRef.current;
    const { popupPosition } = this.state;

    if (popup == null || popupPosition == null) {
      return;
    }

    const rect = popup.getBoundingClientRect();

    const width = document.documentElement.clientWidth;
    const height = document.documentElement.clientHeight;

    const topOffset = rect.top - OFFSET;
    const bottomOffset = height - rect.bottom - OFFSET;
    const top =
      topOffset < 0 ? -topOffset : bottomOffset < 0 ? bottomOffset : 0;

    const leftOffset = rect.left - OFFSET;
    const rightOffset = width - rect.right - OFFSET;
    const left =
      leftOffset < 0 ? -leftOffset : rightOffset < 0 ? rightOffset : 0;

    this.setState({
      popupPosition: {
        top: popupPosition.top + Math.floor(top),
        left: popupPosition.left + Math.floor(left),
      },
      popupAdjusted: true,
    });
  };

  open = () => {
    this.clearTimeout();
    this.setState(
      {
        open: true,
        closing: false,
        popupPosition: undefined,
        popupAdjusted: false,
      },
      () => {
        const popup = this.popupRef.current;
        if (popup != null) {
          popup.focus();
        }
      },
    );
  };

  close = ({ timeout = 0 } = {}) => {
    this.clearTimeout();

    this.timeoutId = setTimeout(() => {
      this.timeoutId = setTimeout(() => {
        this.setState({ open: false, closing: false });
      }, FADE_DURATION);

      this.setState({ closing: true, stayOpen: false });
    }, timeout);
  };

  onClick = () => {
    const { stayOpen } = this.state;
    if (stayOpen) {
      this.close();
    } else {
      this.open();
      this.setState({ stayOpen: true });
    }
  };

  closeIfOutside = event => {
    if (
      event.target instanceof Node &&
      // When an image loads into existence below the mouse cursor, Firefox
      // reports the next click on it as being a click on `document`, causing
      // the popup to close. We can safely ignore clicks on `document` since
      // there will always be elements on top of it.
      event.target !== document &&
      this.buttonRef != null &&
      !this.buttonRef.current.contains(event.target) &&
      this.popupRef != null &&
      !this.popupRef.current.contains(event.target)
    ) {
      this.close();
    }
  };

  render() {
    const { children } = this.props;
    const {
      open,
      stayOpen,
      closing,
      popupPosition = { top: 0, left: 0 },
    } = this.state;

    const content =
      typeof children === "function"
        ? children({
            adjustPopupPosition: this.adjustPopupPosition,
          })
        : children;

    return (
      <WithWindowSize>
        {windowSize => {
          const mobile = windowSize.phabletDown;
          return (
            <div
              className={styles.root}
              onMouseEnter={
                mobile
                  ? null
                  : () => {
                      this.open();
                    }
              }
              onMouseLeave={
                mobile
                  ? null
                  : () => {
                      if (!stayOpen) {
                        this.close({ timeout: MOUSELEAVE_DELAY });
                      }
                    }
              }
            >
              <button
                type="button"
                aria-label="Hjälp"
                aria-expanded={String(open)}
                className={styles.button}
                ref={this.buttonRef}
                onClick={this.onClick}
              >
                <Icon icon={InformationOutline} />
              </button>

              {open &&
                !mobile &&
                appElement != null &&
                ReactDOM.createPortal(
                  <div
                    className={classnames(styles.popup, {
                      [styles.closing]: closing,
                    })}
                    style={{
                      animationDuration: `${FADE_DURATION}ms`,
                      top: popupPosition.top,
                      left: popupPosition.left,
                      marginRight: OFFSET,
                      marginBottom: OFFSET,
                    }}
                    tabIndex="-1"
                    ref={this.popupRef}
                    onClick={() => {
                      this.setState({ stayOpen: true });
                    }}
                    onContextMenu={() => {
                      this.setState({ stayOpen: true });
                    }}
                  >
                    <div className={styles.popupInner}>{content}</div>

                    {/* Re-adjust the popup when images inside it load, since
                    they usually cause the popup to grow bigger. */}
                    <EventListener
                      eventName="load"
                      target={document}
                      listener={this.adjustPopupPosition}
                    />
                  </div>,
                  appElement,
                )}

              {open && !mobile && (
                <>
                  <EventListener
                    eventName="focus"
                    listener={this.closeIfOutside}
                  />

                  <EventListener
                    eventName="click"
                    listener={this.closeIfOutside}
                  />

                  <EventListener
                    eventName="keydown"
                    listener={event => {
                      if (event.key === "Escape") {
                        this.close();
                        const button = this.buttonRef.current;
                        if (button != null) {
                          button.focus();
                        }
                      }
                    }}
                  />
                </>
              )}

              {mobile && (
                <Modal
                  isOpen={open}
                  title="Hjälp"
                  padded
                  onRequestClose={() => {
                    this.setState({
                      open: false,
                      closing: false,
                      stayOpen: false,
                    });
                  }}
                >
                  {content}
                </Modal>
              )}
            </div>
          );
        }}
      </WithWindowSize>
    );
  }
}

PopupDemo.propTypes = {
  children: PropTypes.node.isRequired,
};

export function PopupDemo({ children }) {
  return (
    <div className={styles.popupBase}>
      <div className={styles.popupInner}>{children}</div>
    </div>
  );
}
