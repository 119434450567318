import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import CheckCircleIcon from "../../icons/check-circle.svg";
import CrossIcon from "../../icons/cross.svg";
import PlusCircleIcon from "../../icons/plus-circle.svg";
import {
  getAllChoices,
  hasSeveralChoicesGroups,
  titleChoices,
} from "../../pages-helpers/chat/utils";
import { noop } from "../../utils";
import Blueprint from "../Blueprint";
import Icon from "../Icon";
import Text from "../Text";
import styles from "./styles.scss";

const choicesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    description: PropTypes.string,
    chooseText: PropTypes.string,
    blueprint: PropTypes.object,
    image: PropTypes.string,
    group: PropTypes.number,
  }),
);

export default class ImageChoicesAttachment extends React.Component {
  // This component intentionally ignores any already selected choices from the
  // API. It’s not worth the trouble until we need it.
  static propTypes = {
    choices: PropTypes.shape({
      standard: choicesPropType.isRequired,
      popular: choicesPropType.isRequired,
      other: choicesPropType.isRequired,
    }).isRequired,
    enabled: PropTypes.bool,
    imageWidth: PropTypes.number,
    onSubmit: PropTypes.func.isRequired,
    onScrollToBottomNeeded: PropTypes.func,
  };

  static defaultProps = {
    enabled: true,
    imageWidth: undefined,
    onScrollToBottomNeeded: noop,
  };

  state = {
    selectedValue: undefined,
    showOther: false,
  };

  render() {
    const {
      choices,
      enabled,
      imageWidth: passedImageWidth,
      onSubmit,
      onScrollToBottomNeeded,
    } = this.props;
    const { selectedValue, showOther } = this.state;

    const showTitle = hasSeveralChoicesGroups(choices);
    const allItems = titleChoices(choices);
    const allChoices = getAllChoices(choices);
    const otherHidden = showTitle && choices.other.length > 0 && !showOther;
    const items = otherHidden
      ? allItems.filter(item => item.id !== "other")
      : allItems;
    const lastIndex = items.length - 1;
    const separator = <div className={styles.separator} />;

    const hasDescriptions = allChoices.some(
      choice => choice.description != null,
    );
    const hasChooseTexts = allChoices.some(choice => choice.chooseText != null);

    const imageWidth =
      passedImageWidth == null
        ? hasDescriptions || hasChooseTexts
          ? 150
          : 132
        : passedImageWidth;

    return (
      <div className={styles.root}>
        {showTitle && (
          <div className={styles.headingsRow}>
            {items.map((item, itemIndex) =>
              item.choices.map((_choice, index) => (
                <React.Fragment key={`${item.id}-${index}`}>
                  {itemIndex > 0 && index === 0 && separator}

                  {index === 0 ? (
                    <h2 style={{ width: imageWidth }}>{item.title}</h2>
                  ) : (
                    <div style={{ width: imageWidth }} />
                  )}
                </React.Fragment>
              )),
            )}
          </div>
        )}

        <div className={styles.imagesRow}>
          {items.map((item, itemIndex) => (
            <React.Fragment key={itemIndex}>
              {itemIndex > 0 && separator}

              {item.choices.map((choice, index) => {
                const content =
                  choice.blueprint != null ? (
                    <Blueprint svgWidth={imageWidth} {...choice.blueprint} />
                  ) : choice.image != null ? (
                    <img
                      src={choice.image}
                      alt=""
                      style={{ width: imageWidth }}
                    />
                  ) : (
                    undefined
                  );

                return content == null ? (
                  <div
                    key={index}
                    className={styles.cross}
                    style={{ width: imageWidth }}
                  >
                    <Icon icon={CrossIcon} />
                  </div>
                ) : (
                  <button
                    key={index}
                    type="button"
                    style={{ width: imageWidth }}
                    className={styles.button}
                    disabled={!enabled}
                    onClick={() => {
                      this.setState({ selectedValue: choice.value });
                      onSubmit(choice.value);
                    }}
                  >
                    <div
                      className={styles.imageWrapper}
                      style={{ width: imageWidth }}
                    >
                      {content}

                      {choice.value === selectedValue && (
                        <Icon
                          icon={CheckCircleIcon}
                          className={styles.checkIcon}
                        />
                      )}
                    </div>
                  </button>
                );
              })}

              {otherHidden && itemIndex === lastIndex && (
                <button
                  type="button"
                  style={{ width: imageWidth }}
                  className={styles.showMoreButton}
                  disabled={!enabled}
                  onClick={() => {
                    this.setState({ showOther: true }, onScrollToBottomNeeded);
                  }}
                >
                  <div className={styles.showMoreButtonInner}>
                    <Icon
                      icon={PlusCircleIcon}
                      className={styles.showMoreButtonIcon}
                    />
                    Se fler
                  </div>
                </button>
              )}
            </React.Fragment>
          ))}
        </div>

        <div className={styles.row}>
          {items.map((item, itemIndex) =>
            item.choices.map((choice, index) => (
              <React.Fragment key={index}>
                {itemIndex > 0 && index === 0 && separator}

                <button
                  type="button"
                  style={{ width: imageWidth }}
                  className={styles.button}
                  disabled={!enabled}
                  onClick={() => {
                    this.setState({ selectedValue: choice.value });
                    onSubmit(choice.value);
                  }}
                >
                  <div
                    className={classnames(styles.title, {
                      [styles.boldTitle]: hasDescriptions || hasChooseTexts,
                    })}
                  >
                    {choice.name}
                  </div>
                </button>
              </React.Fragment>
            )),
          )}
        </div>

        {hasDescriptions && (
          <div className={styles.row}>
            {items.map((item, itemIndex) =>
              item.choices.map((choice, index) => (
                <React.Fragment key={index}>
                  {itemIndex > 0 && index === 0 && separator}

                  <div
                    style={{ width: imageWidth }}
                    className={styles.description}
                  >
                    {choice.description == null ? null : (
                      <Text html={choice.description} />
                    )}
                  </div>
                </React.Fragment>
              )),
            )}
          </div>
        )}

        {hasChooseTexts && (
          <div className={styles.row}>
            {items.map((item, itemIndex) =>
              item.choices.map((choice, index) => (
                <React.Fragment key={index}>
                  {itemIndex > 0 && index === 0 && separator}

                  {choice.chooseText == null ? (
                    <div style={{ width: imageWidth }} />
                  ) : (
                    <button
                      type="button"
                      style={{ width: imageWidth }}
                      className={styles.chooseButton}
                      disabled={!enabled}
                      onClick={() => {
                        this.setState({ selectedValue: choice.value });
                        onSubmit(choice.value);
                      }}
                    >
                      <div className={styles.chooseWrapper}>
                        <span className={styles.chooseText}>
                          {choice.chooseText}
                        </span>
                      </div>
                    </button>
                  )}
                </React.Fragment>
              )),
            )}
          </div>
        )}
      </div>
    );
  }
}
