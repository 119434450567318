// Global CSS variables have to be defined in this very file (rather than in
// main/css/globals.js) in order not to have to restart the dev server every
// time you change any of them.

// Grey colors are named after Star Wars characters because naming is hard.
const greyLeia = "#f8f8f8";
const greyLuke = "#f5f8f5";
const greyHan = "#ccc";
const greyBoba = "#999";
const greyVader = "#222";

// "Colorful" colors.
const beige = "#edeae5";
const beigeLight = "#fff8f2";
const green = "#3ec28f";
const orange = "#ef7b10";
const red = "#f5474b";
const yellow = "#fbba00";

// Breakpoints.
const deviceMaxWidths = {
  mobile: 413,
  phablet: 900,
  tablet: 991,
  tabletBig: 1099,
  desktop: 1199,
  desktopBig: 1500,
};

const conversation = {
  maxWidth: 700,
  imageSize: 48,
  imageOffset: 15,
};

const globals = {
  // Grey colors.
  "g-greyLeia": greyLeia,
  "g-greyLuke": greyLuke,
  "g-greyHan": greyHan,
  "g-greyBoba": greyBoba,
  "g-greyVader": greyVader,

  // "Colorful" colors.
  "g-beige": beige,
  "g-beigeLight": beigeLight,
  "g-green": green,
  "g-orange": orange,
  "g-red": red,
  "g-yellow": yellow,

  // Common usage for some colors.
  "g-textPrimaryColor": greyVader,
  "g-textSecondaryColor": greyBoba,
  "g-borderColor": greyHan,
  "g-errorColor": red,
  "g-pageBackgroundColor": "white",
  "g-linkColor": orange,
  "g-linkHoverColor": orange,
  "g-linkHoverDecoration": "underline",

  // Font stuff.
  "g-fontSizeLarger": "1.8rem",
  "g-fontSizeSmaller": "1.6rem",
  "g-lineHeightLarger": `${27 / 18}`,
  "g-lineHeightSmaller": `${21 / 16}`,
  "g-fontFamily": [
    "faricy-new-web",
    "-apple-system",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica",
    "Helvetica Neue",
    "Arial",
    "sans-serif",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
  ].join(", "),

  // The font weights used.
  "g-light": "300",
  "g-bold": "700",

  // All major `z-index` values.
  "g-z-index-Chatbot_main": 1000,
  "g-z-index-Chatbot_sidebar": 1001,
  "g-z-index-ContactMePopup-toggle": 2000,
  "g-z-index-HomeHero": 2001,
  "g-z-index-ContactMePopup-toggleOnTop": 2002,
  "g-z-index-Nav": 9000,
  "g-z-index-Chatbot_mainMobile": 9000,
  "g-z-index-Chatbot_sidebarMobile": 9001,
  "g-z-index-Nav-sideNav": 9100,
  "g-z-index-ContactMePopup-popup": 9101,
  "g-z-index-HelpPopup-popup": 9200,
  "g-z-index-Modal": 10000,
  "g-z-index-Nprogress": 20000,

  // Measurements that need to be shared between several components. They are
  // named after the component that “owns” it (but other components can use it).
  "g-ContactMePopup_buttonHeight": "50px",
  "g-Conversation_max-width": `${conversation.maxWidth}px`,
  "g-Conversation_image-size": `${conversation.imageSize}px`,
  "g-Conversation_image-offset": `${conversation.imageOffset}px`,
  "g-Conversation_padding": `${conversation.imageSize +
    conversation.imageOffset * 2}px`,
  "g-Nav_height": "50px",
  "g-Nav_box-shadow": "0 3px 3px 0 color-mod(black alpha(0.15))",
  "g-Timeline-circle_size": "50px",
  "g-Timeline-circle_size-small": "24px",

  // Common values.
  "g-gutter": "20px",
  "g-animationSpeed": "200ms",

  // Breakpoints.
  "g-maxWidthMobile": deviceMaxWidths.mobile,
  "g-maxWidthPhablet": deviceMaxWidths.phablet,
  "g-maxWidthTablet": deviceMaxWidths.tablet,
  "g-maxWidthTabletBig": deviceMaxWidths.tabletBig,
  "g-maxWidthDesktop": deviceMaxWidths.desktop,
  "g-maxWidthDesktopBig": deviceMaxWidths.desktopBig,

  // Chat width breakpoints.
  chatSmall: maxWidth(600),
  chatLarge: maxWidth(1355),

  // Usage example: `@media ($mobileDown){}`.
  mobileDown: maxWidth(deviceMaxWidths.mobile),
  phabletDown: maxWidth(deviceMaxWidths.phablet),
  tabletDown: maxWidth(deviceMaxWidths.tablet),
  desktopDown: maxWidth(deviceMaxWidths.desktop),
  desktopBigDown: maxWidth(deviceMaxWidths.desktopBig),
  // 'desktopHugeDown' and 'mobileUp' are omitted here because they don’t make
  // sense: They would be the same as not using a media query at all.
  phabletUp: minWidth(deviceMaxWidths.mobile),
  tabletUp: minWidth(deviceMaxWidths.phablet),
  desktopUp: minWidth(deviceMaxWidths.tablet),
  desktopBigUp: minWidth(deviceMaxWidths.desktop),
  desktopHugeUp: minWidth(deviceMaxWidths.desktopBig),
};

module.exports = {
  parser: "postcss-scss",
  plugins: {
    "postcss-simple-vars": { variables: globals },
    "postcss-nested": { preserveEmpty: true },
    "postcss-color-mod-function": {},
    "postcss-calc": { preserve: true },
    autoprefixer: {},
  },
};

function maxWidth(width) {
  return `max-width: ${width}px`;
}

function minWidth(width) {
  return `min-width: ${width + 1}px`;
}
