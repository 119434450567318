// Attachment types as returned by the API.
export const ATTACHMENT_TYPES_API = {
  CHOICES: "choices",
  DATE: "date",
  FORM: "form",
  POSITIONS: "positions",
  WINDOW_SIZE: "window_size",
};

export const PRODUCT_TYPES_API = {
  WINDOW: 10,
  WINDOW_DOOR: 20,
  DOOR: 30,
  SIDE_OR_TOP_WINDOW: 40,
  ROOF_WINDOW: 50,
  FLAT_ROOF_WINDOW: 60,
  GARAGE_DOOR: 70,
};

// The attachment types used in the client. The attachments from the API are
// transformed into these.
export const ATTACHMENT_TYPES = {
  BLUEPRINTS: "BLUEPRINTS",
  // See also `BUTTONS_MODES` below.
  BUTTONS: "BUTTONS",
  DAY: "DAY",
  FORM: "FORM",
  // Used for legacy stuff.
  JSON: "JSON",
  POSITIONS: "POSITIONS",
  SIZE: "SIZE",
};

export const WINDOW_DOOR_WINDOW_HEIGHT = 1200; // mm

export const BUTTONS_MODES = {
  SINGLE: "SINGLE",
  MULTIPLE: "MULTIPLE",
  CUSTOM_TEXT: "CUSTOM_TEXT",
};

export const CHOICES_DISPLAYS = {
  BLUEPRINT: "blueprint",
  BUTTONS: "buttons",
};

export const CHOICES_GROUPS = {
  STANDARD: 0,
  POPULAR: 1,
};

export const FIELD_TYPES = {
  CHAR: "char_field",
  CHAR_WITH_CHOICES: "char_field_choices_field",
  DECIMAL: "decimal_field",
  EMAIL: "email_field",
  INTEGER: "integer_field",
  PHONE: "phone_number_field",
  POSTAL_CODE: "postal_code_field",
};

export const BLUEPRINT_TYPES = {
  WINDOW_DOOR: "WINDOW_DOOR",
  WINDOW: "WINDOW",
};

export const POSITIONS = {
  SECOND_FLOOR_ABOVE_60: 10,
  SECOND_FLOOR_BELOW_60: 20,
  FIRST_FLOOR_ABOVE_60: 30,
  FIRST_FLOOR_BELOW_60: 40,
  BASEMENT: 50,
};

export const OPTIMISTIC_REPLY_ID = "optimistic_reply";
