import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./styles.scss";

// This is a component for entering digits-only data, in either string format
// such as phone numbers, or integer formats, such as quantities.

export default class NumberInput extends React.Component {
  static propTypes = {
    // If `value` is a string, `onChange` will be called with a string.
    // If `value` is a number, `onChange` will be called with a number, or null if
    // the input is empty.
    value: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,

    invalid: PropTypes.bool,
    unit: PropTypes.string,
    styles: PropTypes.shape({
      root: PropTypes.string,
      invalid: PropTypes.string,
    }),

    // Only applicable when `value` is a number:
    min: PropTypes.number,
    max: PropTypes.number,

    // ...restProps are passed to the <input> element.
  };

  static defaultProps = {
    onBlur: undefined,
    invalid: false,
    unit: undefined,
    styles,
    min: 0,
    max: Infinity,
  };

  constructor(props) {
    super(props);

    this.state = {
      value:
        (typeof props.value === "number" && props.value <= 0) ||
        props.value <= props.min
          ? ""
          : this.clampValue(props.value),
    };

    this.input = null;
  }

  clampValue(value, props = this.props) {
    const { value: originalValue, min, max } = props;
    const isNumber = typeof originalValue === "number";

    const clampedValue = isNumber
      ? Math.min(max, Math.max(0, min, Number(value)))
      : value;
    const stringValue =
      typeof clampedValue === "number"
        ? Number.isFinite(clampedValue)
          ? String(clampedValue)
          : "0"
        : value;

    return cleanDigits(stringValue, {
      trimZeroes: isNumber,
    });
  }

  // This is disabled since it causes Safari to jump to the next input in
  // <SizeAttachment> and does not seem to be needed in any case we use
  // <NumberInput> so far.
  // componentWillReceiveProps(nextProps) {
  //   const nextValue = this.clampValue(nextProps.value, nextProps);
  //   if (this.input && nextValue !== this.state.value) {
  //     const newValue = this.updateInputValue(String(nextProps.value));
  //     this.setState({ value: newValue });
  //   }
  // }

  render() {
    const {
      value: originalValue,
      onChange,
      invalid: passedInvalid,
      unit,
      styles: passedStyles,
      min,
      max,
      ...restProps
    } = this.props;
    const { value } = this.state;

    const invalid =
      passedInvalid || (this.clampValue(value) !== value && value !== "");

    const topStyles = { ...styles, ...passedStyles };

    return (
      <label
        className={classnames(styles.flex, topStyles.root, {
          [topStyles.invalid]: invalid,
        })}
      >
        <input
          type="tel"
          defaultValue={originalValue === "" ? "" : value}
          className={styles.input}
          onChange={() => {
            this.onChange();
          }}
          onBlur={() => {
            this.onBlur();
          }}
          onKeyDown={event => {
            this.onKeyDown(event);
          }}
          ref={element => {
            this.input = element;
          }}
          {...restProps}
        />

        {unit != null && <span className={styles.unit}>{unit}</span>}
      </label>
    );
  }

  onArrow(delta) {
    const { input } = this;
    const { value: originalValue } = this.props;
    const { value } = this.state;

    if (typeof originalValue !== "number") {
      return;
    }

    const numberValue = Number(value);
    const newValue = this.clampValue(numberValue + delta);

    if (newValue !== value) {
      input.value = newValue;
    }

    this.changeIfNeeded(newValue);
  }

  onKeyDown(event) {
    switch (event.key) {
      case "ArrowUp":
      case "ArrowDown":
      case "Up": // For older browsers.
      case "Down": // For older browsers.
        event.preventDefault();
        this.onArrow(event.key.endsWith("Up") ? 1 : -1);
        break;

      default:
      // Do nothing.
    }
  }

  onChange() {
    const oldValue = this.input.value;
    const newValue = this.updateInputValue(oldValue);
    this.changeIfNeeded(newValue);
  }

  onBlur() {
    const { input } = this;
    const { onBlur } = this.props;
    const { value } = input;
    const newValue = this.clampValue(value);

    if (newValue !== value) {
      input.value = newValue;
    }

    this.changeIfNeeded(newValue);

    if (onBlur != null) {
      onBlur();
    }
  }

  updateInputValue(value) {
    const { input } = this;
    const { value: originalValue } = this.props;
    const position =
      input.selectionDirection === "backward"
        ? input.selectionStart
        : input.selectionEnd;
    const trimZeroes = typeof originalValue === "number";
    const before = cleanDigits(value.slice(0, position), {
      defaultToZero: false,
      trimZeroes,
    });
    const after = cleanDigits(value.slice(position), {
      defaultToZero: false,
      trimZeroes: trimZeroes && before === "",
    });
    const newValue = `${before}${after}`;
    const newPosition = before.length;

    input.value = newValue;
    input.selectionStart = newPosition;
    input.selectionEnd = newPosition;
    return newValue;
  }

  changeIfNeeded(value) {
    const { value: originalValue } = this.props;
    if (value !== this.state.value) {
      this.setState({ value }, () => {
        this.props.onChange(
          typeof originalValue === "number"
            ? value === ""
              ? null
              : Number(value)
            : value,
        );
      });
    }
  }
}

function cleanDigits(value, { defaultToZero = true, trimZeroes = true } = {}) {
  const newValue = value
    .replace(/\D/g, "")
    .replace(/^0+/, trimZeroes ? "" : "$&");
  return defaultToZero ? newValue || "0" : newValue;
}
