import classnames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { POSITIONS } from "../../pages-helpers/chat/constants";
import styles from "./styles.scss";

const LINE_HEIGHT = 1.25;
const TEXT_LEFT_WIDTH = 75; // px
const TEXT_RIGHT_WIDTH = 80; // px
const TEXT_MARGIN = 15; // px
const FLOOR_HEIGHT = 120; // px
const BASEMENT_HEIGHT = 57; // px
const WINDOW_WIDTH = 47; // px
const WINDOW_HEIGHT_ABOVE_60 = 50; // px
const WINDOW_HEIGHT_BELOW_60 = 70; // px
const WINDOW_HEIGHT_BASEMENT = 30; // px
const WINDOW_OFFSET = 30; // px
const ABOVE_60_LINE_Y = WINDOW_OFFSET + WINDOW_HEIGHT_ABOVE_60 + 2; // px

const FLOOR_BASE = {
  height: FLOOR_HEIGHT,
  windowOffsetVertical: WINDOW_OFFSET,
};

const BASEMENT_BASE = {
  height: BASEMENT_HEIGHT,
  windowOffsetVertical: WINDOW_OFFSET / 2,
};

const STOREYS = [
  {
    ...FLOOR_BASE,
    title: ["Våning 2", "eller högre"],
    windows: [
      {
        value: POSITIONS.SECOND_FLOOR_ABOVE_60,
        height: WINDOW_HEIGHT_ABOVE_60,
      },
      {
        value: POSITIONS.SECOND_FLOOR_BELOW_60,
        height: WINDOW_HEIGHT_BELOW_60,
      },
    ],
  },
  {
    ...FLOOR_BASE,
    title: ["Våning 1"],
    windows: [
      {
        value: POSITIONS.FIRST_FLOOR_ABOVE_60,
        height: WINDOW_HEIGHT_ABOVE_60,
      },
      {
        value: POSITIONS.FIRST_FLOOR_BELOW_60,
        height: WINDOW_HEIGHT_BELOW_60,
      },
    ],
  },
  {
    ...BASEMENT_BASE,
    title: ["Källare"],
    windows: [
      {
        value: POSITIONS.BASEMENT,
        height: WINDOW_HEIGHT_BASEMENT,
      },
    ],
  },
];

const MAX_NUM_WINDOWS = Math.max(
  0,
  ...STOREYS.map(storey => storey.windows.length),
);
const HOUSE_WIDTH =
  MAX_NUM_WINDOWS * WINDOW_WIDTH + (MAX_NUM_WINDOWS + 1) * WINDOW_OFFSET;

export default class PositionsAttachment extends React.Component {
  static propTypes = {
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOf(Object.values(POSITIONS)).isRequired,
      }).isRequired,
    ).isRequired,
    enabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    enabled: true,
  };

  state = {
    selectedValue: undefined,
  };

  render() {
    const { choices, enabled, onClick } = this.props;
    const { selectedValue } = this.state;

    const svgWidth =
      TEXT_LEFT_WIDTH +
      TEXT_MARGIN +
      HOUSE_WIDTH +
      TEXT_MARGIN +
      TEXT_RIGHT_WIDTH;
    const svgHeight = _.sum(STOREYS.map(storey => storey.height));

    return (
      <div className={styles.root}>
        <svg
          viewBox={`0 0 ${svgWidth} ${svgHeight}`}
          style={{ width: svgWidth, height: svgHeight }}
          className={styles.svg}
        >
          {STOREYS.map((storey, index) => {
            const storeyX = TEXT_LEFT_WIDTH + TEXT_MARGIN;
            const storeyY = _.sum(
              STOREYS.slice(0, index).map(storey2 => storey2.height),
            );

            return (
              <Storey
                key={index}
                title={storey.title}
                x={storeyX}
                y={storeyY}
                isBottom={index === STOREYS.length - 1}
                height={storey.height}
              >
                {storey.windows.map((window2, index2) => {
                  const choice = choices.find(
                    choice2 => choice2.value === window2.value,
                  );

                  if (choice == null) {
                    console.error(
                      `No choice with value '${window2.value}' available`,
                      choices,
                    );
                  }

                  return (
                    <Window
                      key={index2}
                      x={
                        storeyX +
                        WINDOW_OFFSET * (index2 + 1) +
                        WINDOW_WIDTH * index2
                      }
                      y={storeyY + storey.windowOffsetVertical}
                      width={WINDOW_WIDTH}
                      height={window2.height}
                      enabled={enabled}
                      selected={choice ? choice.value === selectedValue : false}
                      title={choice ? choice.name : ""}
                      onClick={() => {
                        onClick(window2.value);
                        this.setState({ selectedValue: window2.value });
                      }}
                    />
                  );
                })}
              </Storey>
            );
          })}
        </svg>
      </div>
    );
  }
}

Storey.propTypes = {
  title: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  isBottom: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

function Storey({ title, x, y, height, children, isBottom }) {
  const pointsString = [
    [x, y + height],
    [x, y],
    [x + HOUSE_WIDTH, y],
    [x + HOUSE_WIDTH, y + height],
  ]
    .filter(Boolean)
    .map(coords => coords.join(","))
    .join(" ");

  const lineY = y + ABOVE_60_LINE_Y;
  const lineTextX = x + HOUSE_WIDTH + TEXT_MARGIN;

  return (
    <g>
      <g className={styles.textLeft}>
        {title.map((text, index) => (
          <text
            key={index}
            x={TEXT_LEFT_WIDTH}
            y={y + height / 2}
            dy={`${0.3 / title.length + LINE_HEIGHT * index}em`}
            textAnchor="end"
          >
            {text}
          </text>
        ))}
      </g>

      {isBottom ? (
        <polygon points={pointsString} className={styles.storey} />
      ) : (
        <polyline points={pointsString} className={styles.storey} />
      )}

      {children}

      {height > ABOVE_60_LINE_Y && (
        <g>
          <line
            x1={x + WINDOW_OFFSET}
            y1={lineY}
            x2={x + HOUSE_WIDTH + TEXT_RIGHT_WIDTH - TEXT_MARGIN * 2}
            y2={lineY}
            className={styles.above60line}
          />
          {["över 60 cm", "från golvet"].map((string, index) => (
            <text
              key={index}
              x={lineTextX}
              y={lineY}
              dy={`${(-1 + index) * LINE_HEIGHT - 1}em`}
            >
              {string}
            </text>
          ))}
          {["under 60 cm", "från golvet"].map((string, index) => (
            <text
              key={index}
              x={lineTextX}
              y={lineY}
              dy={`${(1 + index) * LINE_HEIGHT + 0.4}em`}
            >
              {string}
            </text>
          ))}
        </g>
      )}
    </g>
  );
}

Window.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  enabled: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function Window({ x, y, width, height, enabled, selected, title, onClick }) {
  return (
    <rect
      role="button"
      tabIndex="0"
      x={x}
      y={y}
      width={width}
      height={height}
      className={classnames(styles.window, {
        [styles.selected]: selected,
      })}
      disabled={!enabled}
      onClick={() => {
        if (enabled) {
          onClick();
        }
      }}
      onKeyDown={event => {
        if (event.key === "Enter" && enabled) {
          onClick();
        }
      }}
    >
      <title>{title}</title>
    </rect>
  );
}
