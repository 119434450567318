import PropTypes from "prop-types";
import React from "react";
import { UnmountClosed } from "react-collapse";

import PlusCircleIcon from "../../icons/plus-circle.svg";
import { BUTTONS_MODES, FIELD_TYPES } from "../../pages-helpers/chat/constants";
import {
  hasSeveralChoicesGroups,
  titleChoices,
} from "../../pages-helpers/chat/utils";
import { noop } from "../../utils";
import FormAttachment from "../FormAttachment";
import Icon from "../Icon";
import SendButton from "../SendButton";
import ToggleButton from "../ToggleButton";
import styles from "./styles.scss";

const choicesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    group: PropTypes.number,
  }),
);

export default class ButtonsAttachment extends React.Component {
  // This component intentionally ignores any already selected choices from the
  // API. It’s not worth the trouble until we need it.
  static propTypes = {
    mode: PropTypes.oneOf(Object.values(BUTTONS_MODES)).isRequired,
    choices: PropTypes.shape({
      standard: choicesPropType.isRequired,
      popular: choicesPropType.isRequired,
      other: choicesPropType.isRequired,
    }).isRequired,
    enabled: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onAutofocusNeeded: PropTypes.func,
    onScrollToBottomNeeded: PropTypes.func,
  };

  static defaultProps = {
    enabled: true,
    onAutofocusNeeded: noop,
    onScrollToBottomNeeded: noop,
  };

  state = {
    selectedValues: [],
    showOther: false,
    showCustomText: false,
  };

  render() {
    const {
      mode,
      choices,
      enabled,
      onSubmit,
      onAutofocusNeeded,
      onScrollToBottomNeeded,
    } = this.props;
    const { selectedValues, showOther, showCustomText } = this.state;

    const showTitle = hasSeveralChoicesGroups(choices);
    const allItems = titleChoices(choices);
    const otherHidden = showTitle && choices.other.length > 0 && !showOther;
    const items = otherHidden
      ? allItems.filter(item => item.id !== "other")
      : allItems;
    const lastIndex = items.length - 1;

    return (
      <div className={styles.root}>
        <form
          className={styles.groups}
          onSubmit={event => {
            event.preventDefault();
            if (enabled) {
              onSubmit(
                mode === BUTTONS_MODES.MULTIPLE
                  ? selectedValues
                  : selectedValues[0],
              );
            }
          }}
        >
          {items.map((item, itemIndex) => (
            <div key={item.id}>
              {showTitle && <h2 className={styles.heading}>{item.title}</h2>}

              <div className={styles.choices}>
                {item.choices.map((choice, index) => (
                  <div key={index}>
                    <ToggleButton
                      type={
                        mode === BUTTONS_MODES.MULTIPLE ? "button" : "submit"
                      }
                      selected={selectedValues.includes(choice.value)}
                      disabled={!enabled}
                      onClick={() => {
                        this.setState(
                          toggleValue.bind(undefined, choice.value),
                        );
                      }}
                    >
                      {choice.name}
                    </ToggleButton>
                  </div>
                ))}

                {otherHidden && itemIndex === lastIndex && (
                  <div>
                    <ToggleButton
                      highlighted
                      selected={false}
                      disabled={!enabled}
                      onClick={() => {
                        this.setState(
                          { showOther: true },
                          onScrollToBottomNeeded,
                        );
                      }}
                    >
                      <div className={styles.plusButtonInner}>
                        <Icon
                          icon={PlusCircleIcon}
                          className={styles.plusIcon}
                        />
                        Se fler
                      </div>
                    </ToggleButton>
                  </div>
                )}

                {mode === BUTTONS_MODES.CUSTOM_TEXT &&
                  !showCustomText &&
                  itemIndex === lastIndex && (
                    <div>
                      <ToggleButton
                        selected={false}
                        disabled={!enabled}
                        onClick={() => {
                          this.setState({ showCustomText: true });
                        }}
                      >
                        <div className={styles.plusButtonInner}>
                          <Icon
                            icon={PlusCircleIcon}
                            className={styles.plusIconGreen}
                          />
                          Lägg till
                        </div>
                      </ToggleButton>
                    </div>
                  )}

                {mode === BUTTONS_MODES.MULTIPLE && (
                  <div className={styles.sendButtonWrapper}>
                    <SendButton />
                  </div>
                )}
              </div>
            </div>
          ))}
        </form>

        <UnmountClosed isOpened={showCustomText} onRest={onAutofocusNeeded}>
          <FormAttachment
            fields={[
              [
                {
                  type: FIELD_TYPES.CHAR,
                  name: "value",
                  value: "",
                },
              ],
            ]}
            enabled={enabled}
            onSubmit={({ value }) => {
              onSubmit(value);
            }}
          />
        </UnmountClosed>
      </div>
    );
  }
}

function toggleValue(value, state, props) {
  const selected = state.selectedValues.includes(value);

  return {
    selectedValues:
      props.mode === BUTTONS_MODES.MULTIPLE
        ? selected
          ? state.selectedValues.filter(otherValue => otherValue !== value)
          : // It’s important to wrap `value` in an array here, since some choices
            // have arrays as values! Otherwise those arrays will be spread into
            // `selectedValues`.
            state.selectedValues.concat([value])
        : selected
        ? []
        : [value],
  };
}
